interface TrueBizUser {
  id: UUID;
  email: string;
}

interface ExternalUser {
  id: null;
  external_ref_id: string;
  email?: string | null | undefined;
}

export type APIAnomalyUser = TrueBizUser | ExternalUser;

export enum APIAnomalyStatus {
  OPEN = "io.truebiz.monitoring.anomaly.status.open",
  ASSIGNED = "io.truebiz.monitoring.anomaly.status.assigned",
  RESOLVED = "io.truebiz.monitoring.anomaly.status.resolved",
}

export enum APIAnomalyResolutionType {
  CLEARED = "io.truebiz.monitoring.anomaly.resolution.cleared",
  TERMINATED = "io.truebiz.monitoring.anomaly.resolution.terminated",
  NOTICE = "io.truebiz.monitoring.anomaly.resolution.notice",
  OTHER_UNKNOWN = "io.truebiz.monitoring.anomaly.resolution.other-unknown",
}

export enum APIAnomalyActivityType {
  CREATED = "io.truebiz.monitoring.anomaly.activity.created",
  ASSIGNED = "io.truebiz.monitoring.anomaly.activity.assigned",
  RESOLVED = "io.truebiz.monitoring.anomaly.activity.resolved",
  COMMENTED = "io.truebiz.monitoring.anomaly.activity.commented",
}

export enum APIAnomalyOrderBy {
  CREATED_AT_ASC = "created_at",
  CREATED_AT_DESC = "-created_at",
  DOMAIN_ASC = "domain",
  DOMAIN_DESC = "-domain",
  STATUS_ASC = "status",
  STATUS_DESC = "-status",
  ASSIGNED_TO_ASC = "assigned_to",
  ASSIGNED_TO_DESC = "-assigned_to",
  ALERTS_ASC = "alerts",
  ALERTS_DESC = "-alerts",
}

export type APIAnomalyFilterBy =
  | { column: "created"; operator: "before"; value: ISODateTime }
  | { column: "created"; operator: "after"; value: ISODateTime }
  | { column: "domain"; operator: "in"; value: string[] }
  | { column: "assigned_to"; operator: "in"; value: string[] }
  | { column: "status"; operator: "in"; value: APIAnomalyStatus[] }
  | { column: "alerts"; operator: "in"; value: string[] }
  | { column: "id"; operator: "in"; value: UUID[] }
  | { column: "monitor"; operator: "in"; value: UUID[] }
  | { column: "search"; operator: "eq"; value: string };

export interface APIAnomalyAssignment {
  created_at: ISODateTime;
  assigned_to: APIAnomalyUser | null | undefined;
  assigned_by: APIAnomalyUser;
}

export interface APIAnomalyResolution {
  created_at: ISODateTime;
  type: APIAnomalyResolutionType;
  description: string;
  resolved_by: APIAnomalyUser;
}

export interface APIAnomalyInlineMonitor {
  id: UUID;
  external_ref_id: string | null | undefined;

  created_at: ISODateTime;
  deleted_at: ISODateTime | null | undefined;
  domain: string;
  type: "io.truebiz.monitoring.monitor.website-content";
  risk_profile:
    | {
        id: UUID;
        name: string;
      }
    | null
    | undefined;
  last_run: ISODateTime | null | undefined;
  next_run: ISODateTime | null | undefined;
}

export interface APIAnomalyCreatedActivity {
  id: UUID;
  type: APIAnomalyActivityType.CREATED;
  created_at: ISODateTime;
}

export interface APIAnomalyAssignedActivity {
  id: UUID;
  type: APIAnomalyActivityType.ASSIGNED;
  created_at: ISODateTime;
  assignment: APIAnomalyAssignment;
}

export interface APIAnomalyResolvedActivity {
  id: UUID;
  type: APIAnomalyActivityType.RESOLVED;
  created_at: ISODateTime;
  resolution: APIAnomalyResolution;
}

export interface APIAnomalyCommentedActivity {
  id: UUID;
  type: APIAnomalyActivityType.COMMENTED;
  created_at: ISODateTime;
  comment: APIAnomalyComment;
}

export type APIAnomalyActivity =
  | APIAnomalyCreatedActivity
  | APIAnomalyAssignedActivity
  | APIAnomalyResolvedActivity
  | APIAnomalyCommentedActivity;

type APIAnomalyDetectionAlertType = string;

export interface APIAnomalyDetectionAlert {
  id: UUID;
  type: APIAnomalyDetectionAlertType;
  display: string;
  description: string;
}

export enum APIAnomalyLinkType {
  PAGE = "io.truebiz.monitoring.link.page",
  PAGE_CONTENT = "io.truebiz.monitoring.link.page.content",

  NEXT_ITEMS = "io.truebiz.link.next-items",
  PREV_ITEMS = "io.truebiz.link.prev-items",
  PORTAL = "io.truebiz.link.ui-detail",
}

export interface APIAnomalyLink {
  id: UUID;
  type: string;
  href: string;
  display?: string;
}

export interface APIAnomalyDetectionTextSnippet {
  id: UUID;
  type: "io.truebiz.monitoring.detection.snippet.text";
  direct_links: APIAnomalyLink[];
  context: string;
  highlights: {
    id: UUID;
    type: "io.truebiz.monitoring.detection.snippet.text.selection-highlight";
    description: string;
    preview: string;
    selection: {
      starting_codepoint_index: number;
      ending_codepoint_index: number;
    };
  }[];
}

export interface APIAnomalyDetection {
  id: UUID;
  created_at: ISODateTime;
  links: APIAnomalyLink[];
  alerts: APIAnomalyDetectionAlert[];
  snippets: APIAnomalyDetectionTextSnippet[];
}

export interface APIAnomalyComment {
  id: UUID;
  created_at: ISODateTime;
  update_at: ISODateTime | null | undefined;
  commenter: APIAnomalyUser;
  message: string;
}

enum APIMonitoringAnomalyType {
  WEBSITE_CONTENT = "io.truebiz.monitoring.anomaly.website-content",
}

interface APIWebsiteContentAnomaly {
  id: UUID;
  type: APIMonitoringAnomalyType.WEBSITE_CONTENT;

  created_at: ISODateTime;
  updated_at: ISODateTime | null | undefined;
  domain: string;
  monitors: APIAnomalyInlineMonitor[];

  status: APIAnomalyStatus;
  assignment: APIAnomalyAssignment | null | undefined;
  resolution: APIAnomalyResolution | null | undefined;

  detections: APIAnomalyDetection[];
  next_detections: APIAnomalyLink | null | undefined;

  activity: APIAnomalyActivity[];
  next_activity: APIAnomalyLink | null | undefined;

  comments: APIAnomalyComment[];
  next_comments: APIAnomalyLink | null | undefined;
}

type APIMonitoringAnomaly = APIWebsiteContentAnomaly;
export default APIMonitoringAnomaly;
