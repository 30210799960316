import { useCallback, useState, useMemo, ReactNode, useRef } from "react";
import { Drawer } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { pickBy } from "lodash";
import ActiveMonitorsDrawerContext from "./context";
import { ActiveMonitorsDrawerAPI, Prefill } from "./types";

import MonitorList from "./MonitorList";
import MonitorCreate from "./MonitorCreate";
import MonitorEdit from "./MonitorEdit";
import MonitorDelete from "./MonitorDelete";
import MonitorCSVEnroll from "./MonitorCSVEnroll";

import { useTrueBizApi } from "../../../api";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";
import {
  APIMonitoringEnrollmentInput,
  APIMonitoringEnrollment,
} from "../../../types/APIMonitoringEnrollment";

type DrawerView = "closed" | "list" | "create" | "edit" | "delete" | "csv";

export interface Props {
  children?: ReactNode;
}

export default function ActiveMonitorsDrawer({ children }: Props) {
  const queryClient = useQueryClient();
  const api = useTrueBizApi();
  const showToast = useShowNetworkFailureToast();

  const [currentView, setCurrentView] = useState<DrawerView>("closed");
  const [editingDomain, setEditingDomain] = useState<string>("");
  const [prefill, setPrefill] = useState<Partial<Prefill> | undefined>(
    undefined
  );

  const postActionView = useRef<DrawerView>("closed");

  const actionPromise = useRef<Promise<void> | null>(null);
  const resolveActionPromise = useRef<(() => void) | null>(null);
  const rejectActionPromise = useRef<((reason?: any) => void) | null>(null);

  const onShowMonitorList = useCallback(() => {
    postActionView.current = "list";
    setCurrentView("list");
  }, []);

  const onCreateMonitor = useCallback(
    (prefill?: Partial<Prefill>, closeOnComplete = true) => {
      setPrefill(prefill);
      postActionView.current = closeOnComplete ? "closed" : "list";
      setCurrentView("create");

      const promise = new Promise<void>((resolve, reject) => {
        resolveActionPromise.current = resolve;
        rejectActionPromise.current = reject;
      });

      actionPromise.current = promise;
      return promise;
    },
    []
  );

  const onCsvBatchCreateEditMonitors = useCallback((closeOnComplete = true) => {
    postActionView.current = closeOnComplete ? "closed" : "list";
    setCurrentView("csv");

    const promise = new Promise<void>((resolve, reject) => {
      resolveActionPromise.current = resolve;
      rejectActionPromise.current = reject;
    });

    actionPromise.current = promise;
    return promise;
  }, []);

  const onEditMonitor = useCallback(
    (domain: string, closeOnComplete = false) => {
      postActionView.current = closeOnComplete ? "closed" : "list";
      setEditingDomain(domain);
      setCurrentView("edit");

      const promise = new Promise<void>((resolve, reject) => {
        resolveActionPromise.current = resolve;
        rejectActionPromise.current = reject;
      });

      actionPromise.current = promise;
      return promise;
    },
    []
  );

  const onDeleteMonitor = useCallback(
    (domain: string, closeOnComplete = true) => {
      postActionView.current = closeOnComplete ? "closed" : "list";
      setEditingDomain(domain);
      setCurrentView("delete");

      const promise = new Promise<void>((resolve, reject) => {
        resolveActionPromise.current = resolve;
        rejectActionPromise.current = reject;
      });

      actionPromise.current = promise;
      return promise;
    },
    []
  );

  const onClose = useCallback(() => {
    setCurrentView("closed");
    postActionView.current = "list";
    setEditingDomain("");
    setPrefill(undefined);

    rejectActionPromise.current?.();

    actionPromise.current = null;
    resolveActionPromise.current = null;
    rejectActionPromise.current = null;
  }, []);

  const drawerApi = useMemo<ActiveMonitorsDrawerAPI>(
    () => ({
      isOpen: currentView !== "closed",
      createMonitor: onCreateMonitor,
      csvBatchCreateEditMonitors: onCsvBatchCreateEditMonitors,
      editMonitor: onEditMonitor,
      deleteMonitor: onDeleteMonitor,
      showMonitorList: onShowMonitorList,
      close: onClose,
    }),
    [
      currentView,
      onClose,
      onShowMonitorList,
      onCreateMonitor,
      onCsvBatchCreateEditMonitors,
      onEditMonitor,
      onDeleteMonitor,
    ]
  );

  const deleteMutation = useMutation({
    mutationFn: async ({ domain }: { domain: string }) => {
      await api.unEnrollMonitoringDomain({ domain });
    },
    mutationKey: ["monitors", "delete"],
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ["allMonitors"] });
      const previous = queryClient.getQueryData(["allMonitors"]);

      if (previous === undefined) return; // query hasn't been populated yet

      queryClient.setQueryData(["allMonitors"], (old: any) => {
        const updated = {
          ...old,
          monitors: old.monitors.filter(
            (monitor: APIMonitoringEnrollment) =>
              monitor.domain !== variables.domain
          ),
        };

        return updated;
      });

      return { previous };
    },
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["allMonitors"],
        refetchType: "all",
      });

      queryClient.invalidateQueries({
        queryKey: ["getMonitors"],
        refetchType: "all",
      });

      await queryClient.invalidateQueries({
        queryKey: ["monitor portfolio"],
        refetchType: "all",
      });
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(["allMonitors"], context.previous);
      }
      showToast();
    },
  });

  const createMutation = useMutation({
    mutationFn: async (variables: APIMonitoringEnrollmentInput) => {
      const cleaned = pickBy(variables); // coerce empty strings to nulls
      const monitor = await api.enrollMonitoringDomain({
        ...cleaned,
      } as APIMonitoringEnrollmentInput);

      queryClient.setQueryData(["getMonitors", monitor.domain], {
        limit: 1,
        offset: 0,
        enrollments: [monitor],
      });

      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["getMonitor", monitor.domain],
          refetchType: "all",
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["getMonitors", monitor.domain],
          refetchType: "all",
          exact: true,
        }),
      ]);

      await queryClient.invalidateQueries({
        queryKey: ["monitor portfolio"],
        refetchType: "all",
      });

      return monitor;
    },
    mutationKey: ["monitors", "create"],
    onMutate: async (variables) => {
      // TODO: optimistic update
      return { previous: undefined };
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["allMonitors"] });
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(["allMonitors"], context.previous);
      }
      showToast();
    },
  });

  const editMutation = useMutation({
    mutationFn: async (variables: APIMonitoringEnrollmentInput) => {
      const cleaned = pickBy(variables); // coerce empty strings to nulls
      const monitor = await api.reEnrollMonitoringDomain(cleaned as any);
      return monitor;
    },
    mutationKey: ["monitors", "edit"],
    onMutate: async (variables) => {
      // TODO: optimistic update
      return { previous: undefined };
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["allMonitors"] });
      queryClient.invalidateQueries({
        queryKey: ["getMonitor", variables.domain],
      });
    },
    onError: (err, variables, context) => {
      const listQueryKey = ["allMonitors"];
      if (context?.previous) {
        if (listQueryKey) {
          queryClient.setQueryData(listQueryKey, context.previous);
        }
      }
      showToast();
    },
  });

  return (
    <ActiveMonitorsDrawerContext.Provider value={drawerApi}>
      {children}
      <Drawer
        anchor="right"
        open={drawerApi.isOpen}
        ModalProps={{ keepMounted: false }}
        onClose={
          createMutation.isPending ||
          editMutation.isPending ||
          deleteMutation.isPending
            ? undefined
            : onClose
        }
      >
        {currentView === "list" && (
          <MonitorList
            onClose={() => {
              onClose();
            }}
            onCreateMonitor={() => {
              onCreateMonitor({}, false);
            }}
            onDeleteMonitor={async (domain: string) => {
              await deleteMutation.mutateAsync({ domain });
            }}
            onEditMonitor={(domain) => {
              onEditMonitor(domain);
            }}
          />
        )}
        {currentView === "create" && (
          <MonitorCreate
            onCancel={() => {
              setCurrentView(postActionView.current);
              setPrefill(undefined);

              rejectActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            onDone={() => {
              setCurrentView(postActionView.current);
              setPrefill(undefined);

              resolveActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            onCreateMonitor={async (formData) =>
              createMutation.mutateAsync(formData)
            }
            prefill={prefill}
          />
        )}
        {currentView === "csv" && (
          <MonitorCSVEnroll
            onCancel={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              rejectActionPromise.current?.();
              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            onDone={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              resolveActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
          />
        )}
        {currentView === "edit" && (
          <MonitorEdit
            onCancel={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              rejectActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            onDone={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              resolveActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            monitorDomain={editingDomain}
            onEditMonitor={async (domain, formData) =>
              await editMutation.mutateAsync({ ...formData })
            }
          />
        )}
        {currentView === "delete" && (
          <MonitorDelete
            onCancel={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              rejectActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            onDone={() => {
              setEditingDomain("");
              setCurrentView(postActionView.current);

              resolveActionPromise.current?.();

              actionPromise.current = null;
              resolveActionPromise.current = null;
              rejectActionPromise.current = null;
            }}
            monitorDomain={editingDomain}
            onDelete={async (domain) => {
              await deleteMutation.mutateAsync({ domain });
            }}
          />
        )}
      </Drawer>
    </ActiveMonitorsDrawerContext.Provider>
  );
}
