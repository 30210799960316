import { Typography, Modal, Paper, Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import RiskToggle from "./RiskToggle";
import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

export default function TrafficModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
  readOnly,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: APICompanyMatchProfile;
  updateMatchProfile: any;
  readOnly: boolean;
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Edit Traffic Risks
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 3, color: grey[800], fontStyle: "italic" }}
            >
              Activate risks to have them run on each search. Deactivate each
              risk you do not want to skip for all searches.
            </Typography>
            <RiskToggle
              label="Activate high referring site traffic risk"
              description="This notice is triggered when a large percentage of traffic comes from a single source."
              value={matchProfile.enable_referring_site}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_referring_site: !matchProfile.enable_referring_site,
                })
              }
            />
            <RiskToggle
              label="Activate traffic from unexpected countries risk"
              description="This notice indicates that a large portion of web traffic is coming from prohibited countries."
              value={matchProfile.enable_country_traffic}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_country_traffic: !matchProfile.enable_country_traffic,
                })
              }
            />
            <RiskToggle
              label="Activate high paid search rate risk"
              description="This notice indicates that the majority of traffic is coming from paid ads."
              value={matchProfile.enable_paid_search_rate}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_paid_search_rate:
                    !matchProfile.enable_paid_search_rate,
                })
              }
            />
            <RiskToggle
              label="Activate high website bounce rate risk"
              description="This notice indicates that the majority of traffic is leaving the site after just visiting one page."
              value={matchProfile.enable_bounce_rate}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_bounce_rate: !matchProfile.enable_bounce_rate,
                })
              }
            />
            <RiskToggle
              label="Activate low average time on site risk"
              description="This notice indicates that users leave the site quickly, signaling low engagement."
              value={matchProfile.enable_time_on_site}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_time_on_site: !matchProfile.enable_time_on_site,
                })
              }
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
