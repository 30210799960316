import {
  Box,
  CardActionArea,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";

import FlaggedConcerns from "./FlaggedConcerns";
import APINewsArticle from "../../../types/APINewsArticle";
import { getRelativeTimeString } from "../../../utilities/formatting";

export interface Props {
  article: APINewsArticle;
  index: number;
}

export default function Article({ article, index }: Props) {
  const publishedDate = article.date ? new Date(article.date) : null;
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", mt: index === 0 ? "0px" : "16px" }}>
      <CardActionArea component={Link} href={article.link} target="_blank">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography component="div" variant="subtitle1">
            <strong>{article.title}</strong>
          </Typography>
          {article.snippet && (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              marginTop="4px"
              lineHeight={1.25}
            >
              {article.snippet}
            </Typography>
          )}
          <Box mt="4px">
            <Grid
              container
              spacing={2}
              columnSpacing={{ md: 6 }}
              rowSpacing={{ md: 2 }}
            >
              <Grid item xs={6}>
                {article.concerns ? (
                  <FlaggedConcerns flaggedConcerns={article.concerns} />
                ) : (
                  <Typography variant="caption">
                    No concerning content found
                  </Typography>
                )}
              </Grid>
              {publishedDate && (
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      paddingTop: "7px",
                      paddingRight: "8px",
                      textAlign: "right",
                    }}
                    variant="subtitle2"
                    color={theme.palette.grey[600]}
                  >
                    Published {getRelativeTimeString(publishedDate, "en-US")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </CardActionArea>
    </Box>
  );
}
