import { useState, useMemo, Fragment } from "react";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  TableContainer,
  IconButton,
  Stack,
  Chip,
  TableHead,
  Collapse,
} from "@mui/material";
import type { TableContainerProps } from "@mui/material/TableContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { grey } from "@mui/material/colors";
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  WarningAmberSharp as WarningAmberSharpIcon,
  FindInPage as FindInPageIcon,
} from "@mui/icons-material";
import { countBy, orderBy } from "lodash";

import { isAlert, getTooltipLabel, sortConnectedEntities } from "./utils";
import NameLink from "./NameLink";

import ConnectedEntityDetailModal from "../ConnectedEntityDetailModal";
import ConnectedEntityDetail from "../ConnectedEntityDetail";
import Tooltip from "../../Tooltip";
import APIConnectedEntity from "../../../types/APIConnectedEntity";
import isReadOnly from "../../../utilities/isReadOnly";
import PrintOnly, { useIsPrint } from "../../PrintOnly";
import WebOnly from "../../WebOnly";

interface SubtableProps extends TableContainerProps {
  connectedEntities: APIConnectedEntity[];
}

function Subtable({ connectedEntities, ...etc }: SubtableProps) {
  return (
    <TableContainer {...etc}>
      <Table size="small" aria-label="contact info table">
        <TableBody>
          {connectedEntities.map((connectedEntity) => (
            <Fragment key={JSON.stringify(connectedEntity)}>
              <TableRow
                key={connectedEntity.name || connectedEntity.domain}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ padding: 0 }}>
                  {/* TODO: add title text and make clickable to open modal */}
                  {isAlert(connectedEntity) && (
                    <WarningAmberSharpIcon color="warning" fontSize="small" />
                  )}
                </TableCell>
                <TableCell sx={{ padding: 0 }}>
                  {/* TODO: add external link icon, make default click open modal */}
                  <NameLink
                    name={connectedEntity.name}
                    domain={connectedEntity.domain}
                  />
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {connectedEntity.sources.map((source: any) => (
                    <Box
                      key={JSON.stringify(source)}
                      sx={{
                        display:
                          connectedEntity.sources.length > 2
                            ? "block"
                            : "inline-block",
                      }}
                    >
                      <Tooltip
                        key={source.type}
                        title={getTooltipLabel(source.type)}
                        placement="right"
                        arrow
                      >
                        <Chip
                          sx={{
                            height: "1.5em",
                            ".MuiChip-label": {
                              paddingLeft: "0.5em",
                              paddingRight: "0.5em",
                            },
                            color: grey[700],
                            borderColor: grey[300],
                          }}
                          label={
                            <Stack
                              direction="row"
                              sx={{
                                alignItems: "center",
                                gap: "0.25em",
                              }}
                            >
                              {source.type}
                              <HelpOutlineOutlinedIcon fontSize="inherit" />
                            </Stack>
                          }
                          variant="outlined"
                        />
                      </Tooltip>
                    </Box>
                  ))}
                </TableCell>
                <WebOnly>
                  <TableCell align="right" sx={{ padding: 0, width: "90px" }}>
                    <ConnectedEntityDetailModal
                      connectedEntity={connectedEntity}
                    />
                    {!isReadOnly() && connectedEntity.domain && (
                      <Link
                        href={`${window.location.origin}/domain/${connectedEntity.domain}?run=1`}
                        target="_blank"
                      >
                        <Tooltip
                          title={
                            "Run full web presence search on this domain in a new tab."
                          }
                          placement="right"
                          arrow
                        >
                          <IconButton>
                            <FindInPageIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    )}
                  </TableCell>
                </WebOnly>
              </TableRow>
              <PrintOnly>
                <ConnectedEntityDetail
                  connectedEntity={connectedEntity}
                  sx={{ display: "table-row" }}
                />
                <Divider sx={{ display: "table-row" }} />
                <div style={{ display: "table-row", height: "2.5em" }} />
              </PrintOnly>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export interface Props {
  connectedEntities: APIConnectedEntity[];
}

export default function ConnectedEntitiesTable({ connectedEntities }: Props) {
  const isPrint = useIsPrint();

  const sortedConnectedEntities = useMemo(
    () => sortConnectedEntities(connectedEntities),
    [connectedEntities]
  );
  const sourceTallies = useMemo(
    () =>
      countBy(
        connectedEntities.flatMap((entity) => entity.sources),
        "type"
      ),
    [connectedEntities]
  );

  const [openSections, setOpenSections] = useState<string[]>(
    isPrint
      ? Object.keys(sourceTallies) // show all for print layout
      : // if there is only one type of connection, and the count is low (i.e. <= 5), auto expand the table
      Object.keys(sourceTallies).length === 1 &&
        sourceTallies[Object.keys(sourceTallies)[0]] <= 5
      ? [Object.keys(sourceTallies)[0]]
      : []
  );

  return (
    <Box data-testid="website-content-wrapper">
      <Grid container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 0 }}>
                <strong>Connection type</strong>
              </TableCell>
              <TableCell sx={{ textAlign: "right", padding: 0 }}>
                <strong>Count</strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orderBy(Object.entries(sourceTallies), (x) => x[1], ["desc"]).map(
              ([sourceType, sourceTypeCount]) => (
                <Fragment key={sourceType}>
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        setOpenSections((sections) => {
                          if (openSections.includes(sourceType)) {
                            return sections.filter((s) => s !== sourceType);
                          }
                          return [...sections, sourceType];
                        });
                      }}
                      sx={{
                        cursor: "pointer",
                        padding: 0,
                      }}
                    >
                      <IconButton>
                        <ExpandMoreIcon
                          fontSize="small"
                          sx={{
                            transform: !openSections.includes(sourceType)
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                        />
                      </IconButton>
                      {sourceType}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right", padding: 0 }}>
                      {sourceTypeCount}
                    </TableCell>
                  </TableRow>
                  <Collapse in={openSections.includes(sourceType)}>
                    {openSections.includes(sourceType) && (
                      <Subtable
                        sx={{
                          marginLeft: "2.5em",
                        }}
                        connectedEntities={sortedConnectedEntities.filter(
                          (entity) =>
                            entity.sources
                              .map((s) => s.type)
                              .includes(sourceType)
                        )}
                      />
                    )}
                  </Collapse>
                </Fragment>
              )
            )}
          </TableBody>
        </Table>
      </Grid>
    </Box>
  );
}
