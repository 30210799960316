import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { tzAwareHumanizeTimeString } from "../../utilities/formatting";
import APICompanyMatchProfile from "../../types/APICompanyMatchProfile";

const ROWS_PER_TABLE_PAGE = 10;
const DEFAULT_MATCH_PROFILE = {
  id: "default",
  name: "TrueBiz Default",
  created_at: "2022-06-04T15:13:07.000Z",
  updated_at: new Date().toISOString(),
  status: "live",
  risk_overrides: null,
  enable_adverse_media: true,
  enable_negative_business_reviews: true,
  enable_paid_search_rate: true,
  enable_time_on_site: true,
  enable_country_traffic: true,
  enable_referring_site: true,
  enable_bounce_rate: true,
  enable_policies: true,
  red_country_codes: [],
  yellow_country_codes: [],
  red_mcc_codes: [],
  yellow_mcc_codes: [],
  red_content_categories: [],
  yellow_content_categories: [],
};

export default function MatchProfiles({
  matchProfiles,
}: {
  matchProfiles: APICompanyMatchProfile[] | null;
}) {
  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const profiles =
    !!matchProfiles && matchProfiles.length > 0
      ? matchProfiles
      : [DEFAULT_MATCH_PROFILE];

  profiles.sort(function (a, b) {
    if (!a.updated_at || !b.updated_at) {
      return -1;
    }

    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });

  const defaultIndex = profiles.findIndex(
    (profile) => profile.id === "default"
  );
  if (defaultIndex === -1) {
    profiles.unshift(DEFAULT_MATCH_PROFILE);
  } else if (defaultIndex > 0) {
    profiles.splice(defaultIndex, 1);
    profiles.unshift(DEFAULT_MATCH_PROFILE);
  }

  return (
    <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{ fontWeight: "normal", mb: 1 }}
        >
          Risk Profiles
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            component={NavLink}
            to="/settings/risk_profile/create"
          >
            Create Profile
          </Button>
        </Box>
      </Box>

      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Created at</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Updated at</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Status</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles
              .filter((profile) => profile.status !== "deleted")
              .slice(
                page * ROWS_PER_TABLE_PAGE,
                page * ROWS_PER_TABLE_PAGE + ROWS_PER_TABLE_PAGE
              )
              .map((matchProfile) => {
                return (
                  <TableRow key={matchProfile.id}>
                    <TableCell>{matchProfile.name}</TableCell>
                    <TableCell align="center">
                      {tzAwareHumanizeTimeString(matchProfile.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {tzAwareHumanizeTimeString(matchProfile.updated_at)}
                    </TableCell>
                    <TableCell align="center">
                      {matchProfile.status?.replace(/\b[a-z]/g, (x) =>
                        x.toUpperCase()
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {matchProfile.name === "TrueBiz Default" ? (
                        <Link
                          sx={{ mr: 2 }}
                          href={`${window.location.origin}/settings/risk_profile/view/default`}
                        >
                          View
                        </Link>
                      ) : (
                        <>
                          <Link
                            sx={{ mr: 2 }}
                            href={`${window.location.origin}/settings/risk_profile/view/${matchProfile.id}`}
                          >
                            View
                          </Link>
                          <Link
                            href={`${window.location.origin}/settings/risk_profile/edit/${matchProfile.id}`}
                          >
                            Edit
                          </Link>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {profiles.length > ROWS_PER_TABLE_PAGE && (
        <TablePagination
          component="div"
          count={profiles.length}
          rowsPerPage={ROWS_PER_TABLE_PAGE}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[ROWS_PER_TABLE_PAGE]}
        />
      )}
    </Paper>
  );
}
