import { Typography, Modal, Paper, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";

import RiskToggle from "./RiskToggle";
import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

export default function PoliciesModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
  readOnly,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: APICompanyMatchProfile;
  updateMatchProfile: any;
  readOnly: boolean;
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Policy Risks
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 3, color: grey[800], fontStyle: "italic" }}
            >
              Activate risks to have them run on each search. Deactivate each
              risk you do not want to skip for all searches.
            </Typography>
            <RiskToggle
              label="Activate policy analysis risk"
              description="This risk analyzes website policies, like as the privacy and return policy."
              value={matchProfile.enable_policies}
              onToggle={() =>
                !readOnly &&
                updateMatchProfile({
                  enable_policies: !matchProfile.enable_policies,
                })
              }
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
