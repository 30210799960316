import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { Error } from "@mui/icons-material";

import { uniq } from "lodash";
import { useMemo } from "react";
import FlaggedConcerns from "./FlaggedConcerns";
import Article from "./Article";
import APINewsArticle from "../../../types/APINewsArticle";

export const NewsArticles = ({ articles }: { articles: APINewsArticle[] }) => {
  const theme = useTheme();

  const flaggedConcerns = useMemo(
    () => uniq(articles.flatMap((a) => a.concerns || [])),
    [articles]
  );

  return (
    <>
      {flaggedConcerns.length > 0 && (
        <>
          <Typography
            mb="1em"
            component="div"
            fontWeight={700}
            color={theme.palette.error.main}
            display="flex"
            alignItems="center"
            gap="0.25em"
          >
            <Error fontSize="small" />
            <span>Concerning news articles detected</span>
          </Typography>
          <FlaggedConcerns flaggedConcerns={flaggedConcerns} />
          <Divider sx={{ mt: 3 }} />
        </>
      )}
      <Grid
        container
        spacing={2}
        columnSpacing={{ md: 6 }}
        rowSpacing={{ md: 2 }}
        sx={{ pt: 3 }}
      >
        <Grid item xs={12}>
          {articles.map((article, index) => {
            return (
              <Article article={article} index={index} key={article.link} />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
