import { useTheme, Chip } from "@mui/material";
import { orderBy } from "lodash";

import FlaggedConcernIcon from "./FlaggedConcernIcon";

import { APINewsArticleType } from "../../../../types/APINewsArticleType";
import { humanizeString } from "../../../../utilities/formatting";
import { Optional } from "../../../../utilities/types";

export const humanizeFlaggedConcern = (
  flaggedConcern: APINewsArticleType | string
) => {
  switch (flaggedConcern) {
    case APINewsArticleType.BANKRUPTCY:
      return "Bankruptcy";
    case APINewsArticleType.FRAUD:
      return "Fraud concerns";
    case APINewsArticleType.LEGAL:
      return "Legal issues";
    default:
      return humanizeString(flaggedConcern);
  }
};

export interface Props {
  flaggedConcerns: Optional<APINewsArticleType[]>;
}

export default function FlaggedConcerns({ flaggedConcerns }: Props) {
  const theme = useTheme();

  if (!flaggedConcerns?.length) return <>{null}</>;

  return (
    <>
      {orderBy(flaggedConcerns, (x) => x, "asc").map((flag) => {
        return (
          <Chip
            key={flag}
            icon={<FlaggedConcernIcon flag={flag} fontSize="small" />}
            label={humanizeFlaggedConcern(flag)}
            variant="outlined"
            sx={{ borderColor: theme.palette.grey[300], cursor: "pointer" }}
          />
        );
      })}
    </>
  );
}
