import queryString from "query-string";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

export default function MonitoringAlerts() {
  // (for now) redirect the stable alerts url to open the
  // issue/anomaly details modal in the portfolio view

  const [searchParams] = useSearchParams();
  const routeParams = useParams();
  const alertId = routeParams?.alertId;

  return (
    <Navigate
      to={{
        pathname: "portfolio",
        search: queryString.stringify({
          ...queryString.parse(searchParams.toString()),
          ...(alertId ? { issueId: alertId } : {}),
        }),
      }}
    />
  );
}
