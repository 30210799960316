import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Container } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  Routes,
  Route,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import LookupDomain, { PrefetchLookupDomain } from "./components/LookupDomain";
import LookupCompany from "./components/LookupCompany";
import BusinessAttributes from "./components/BusinessAttributes";
import JSONResponse from "./components/JSONResponse";
import PageHeader from "./components/PageHeader";
import SearchHistory from "./components/SearchHistory";
import BlockedDomains from "./components/BlockedDomains";
import MonitoringPortfolio from "./components/Monitoring";
import MonitoringAlerts from "./components/Monitoring/MonitoringAlerts";
import Settings from "./components/Settings";
import MatchProfileForm from "./components/MatchProfile/MatchProfileForm";
import Passfort from "./components/Passfort";
import Relay from "./components/Relay";
import Admin from "./components/Admin";
import { Authenticated, Anonymous } from "./components/IdentityProvider";
import ActiveMonitorsDrawerV3 from "./components/Monitoring/ActiveMonitorsDrawer";

import "./App.css";
import DomainFinder from "./components/DomainFinder";
import WebsiteStatusCheck from "./components/WebsiteStatusCheck";
import SearchParameters from "./components/SearchParameters";
import Oops from "./components/Oops";

import TKHomeBusinessAttributes from "./components/BusinessAttributes/TKHome";
import useAdminSettings from "./utilities/adminSettings";

Sentry.init({
  dsn: "https://ee0b5bf24273cc8363d40d1d4b0eb083@o1377546.ingest.sentry.io/4505886273699840",
  enabled: process.env.NODE_ENV !== "test",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set between 0.0 - 1.0 depending on desired trace sample rate frequency
  tracesSampleRate: 1.0,

  // controls for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.truebiz\.io/],

  // Set between 0.0 - 1.0 for desired capturing of all sessions,
  replaysSessionSampleRate: 0.1,
  // Set between 0.0 - 1.0 for desired capturing of sessions with an error,
  replaysOnErrorSampleRate: 1.0,
});

export default function App() {
  const [settings] = useAdminSettings();

  const responseDisplayRoutes = (
    <>
      <Route path="" element={<BusinessAttributes />} />
      <Route path="parameters" element={<SearchParameters />} />
      <Route path="json" element={<JSONResponse />} />
    </>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PrefetchLookupDomain />

      <ActiveMonitorsDrawerV3>
        <DomainFinder>
          <WebsiteStatusCheck>
            <Routes>
              <Route index element={Authenticated(<LookupDomain />)} />
              <Route
                path="/domain/:domain"
                element={Authenticated(<LookupDomain />)}
              />

              <Route
                path="/blocked"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <BlockedDomains />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/searches"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <SearchHistory />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/search/:domain/:searchId"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <LookupCompany />
                    </Container>
                  </>
                )}
              >
                {responseDisplayRoutes}
              </Route>

              {settings.allowTKHomeMock === true && (
                <Route
                  path="/portfolio/tkhome.ca"
                  element={Authenticated(
                    <>
                      <PageHeader invert />
                      <Container maxWidth="xl">
                        <LookupCompany />
                        <TKHomeBusinessAttributes />
                      </Container>
                    </>
                  )}
                />
              )}

              {settings.allowTKHomeMock === true && (
                <Route
                  path="/portfolio/tkhome.ca/latest"
                  element={Authenticated(
                    <>
                      <PageHeader invert />
                      <Container maxWidth="xl">
                        <LookupCompany />
                        <TKHomeBusinessAttributes after />
                      </Container>
                    </>
                  )}
                />
              )}

              {/* This route is for stable alert urls in the public monitoring api */}
              {/* it must come before the catch-all defined below */}
              <Route
                path="/monitoring/alerts/:alertId"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <MonitoringAlerts />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/monitoring/*"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <MonitoringPortfolio />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/settings"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <Settings />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/settings/risk_profile/create"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <MatchProfileForm readOnly={false} />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/settings/risk_profile/view/:matchProfileId"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <MatchProfileForm readOnly={true} />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/settings/risk_profile/edit/:matchProfileId"
                element={Authenticated(
                  <>
                    <PageHeader invert />
                    <Container maxWidth="xl">
                      <MatchProfileForm readOnly={false} />
                    </Container>
                  </>
                )}
              />

              <Route
                path="/passfort/search/:searchId"
                element={Anonymous(
                  <>
                    <PageHeader
                      logo
                      divider
                      navLinks={false}
                      hamburgerMenu={false}
                      invert
                    />
                    <Container maxWidth="xl">
                      <Passfort />
                    </Container>
                  </>
                )}
              >
                {responseDisplayRoutes}
              </Route>

              <Route
                path="/relay/search/:searchId"
                element={Anonymous(
                  <>
                    <PageHeader
                      logo
                      divider
                      navLinks={false}
                      hamburgerMenu={false}
                      invert
                    />
                    <Container maxWidth="xl">
                      <Relay />
                    </Container>
                  </>
                )}
              >
                {responseDisplayRoutes}
              </Route>

              <Route
                path="/qqqadmin"
                element={Authenticated(
                  <>
                    <PageHeader />
                    <Container maxWidth="xl">
                      <Admin />
                    </Container>
                  </>
                )}
              />

              <Route
                path="*"
                element={
                  <Oops message="We couldn’t find the page you were looking for." />
                }
              />
            </Routes>
          </WebsiteStatusCheck>
        </DomainFinder>
      </ActiveMonitorsDrawerV3>
    </LocalizationProvider>
  );
}
