import APIRiskName from "../../../../types/APIRiskName";

export enum TileType {
  SENTIMENT = "sentiment",
  CONTENT = "content",
  WEBSITE_OPERATIONS = "website_operations",
  PHYSICAL_OPERATIONS = "physical_operations",
  APPLICATION = "application",
}

export const getTileForRisk = (risk: APIRiskName): TileType => {
  switch (risk) {
    case APIRiskName.HAS_ADVERSE_NEWS_MEDIA:
    case APIRiskName.HAS_CONCERNING_REVIEWS:
    case APIRiskName.HAS_MIXED_REPUTATION:
    case APIRiskName.HAS_NEGATIVE_REPUTATION:
    case APIRiskName.HAS_POSITIVE_REPUTATION:
    case APIRiskName.IS_NO_SOCIAL_MEDIA_PROFILES_FOUND:
    case APIRiskName.IS_SOCIAL_MEDIA_PROFILES_FOUND:
    case APIRiskName.MIXED_REVIEW_SENTIMENT:
    case APIRiskName.REVIEWS_ENGAGEMENT:
    case APIRiskName.SOCIAL_MEDIA_ENGAGEMENT:
      return TileType.SENTIMENT;

    case APIRiskName.HAS_CUSTOM_PROHIBITED_WEB_CONTENT:
    case APIRiskName.HAS_HIGH_RISK_BRANDS:
    case APIRiskName.HAS_HIGH_RISK_WEB_CONTENT:
    case APIRiskName.HAS_POLICY_NO_REFUNDS:
    case APIRiskName.HAS_POLICY_NOT_TIMELY_REFUNDS:
    case APIRiskName.HAS_PROHIBITED_BRANDS:
    case APIRiskName.HAS_PROHIBITED_WEB_CONTENT:
    case APIRiskName.HAS_SUBSCRIPTIONS:
    case APIRiskName.HOMEPAGE_HAS_MINIMAL_CONTENT:
    case APIRiskName.IS_INDUSTRY_CLASSIFIED:
    case APIRiskName.IS_INDUSTRY_ELEVATED_RISK:
    case APIRiskName.IS_INDUSTRY_HIGH_RISK:
    case APIRiskName.IS_INDUSTRY_PROHIBITED:
    case APIRiskName.IS_PRIVACY_POLICY_SUSPICIOUS:
    case APIRiskName.IS_UNEXPECTED_CURRENCY_CODE:
    case APIRiskName.IS_WEBSITE_CONTENT_DUPLICATED:
      return TileType.CONTENT;

    case APIRiskName.HAS_ALL_INVALID_EMAILS:
    case APIRiskName.HAS_APEX_AND_WWW:
    case APIRiskName.HAS_CONCERNINGLY_RECENT_REGISTRATION:
    case APIRiskName.HAS_DISCOVERABLE_WEB_TRAFFIC:
    case APIRiskName.HAS_EMAIL_DOMAIN_MATCHED:
    case APIRiskName.HAS_EMAIL_DOMAIN_NOT_MATCHED:
    case APIRiskName.HAS_HIGH_REFERRING_SITE_TRAFFIC:
    case APIRiskName.HAS_HIGH_SINGLE_DOMAIN_REFERRING_SITE_TRAFFIC:
    case APIRiskName.HAS_HIGH_WEBSITE_BOUNCE_RATE:
    case APIRiskName.HAS_HIGH_WEBSITE_PAID_SEARCH_RATE:
    case APIRiskName.HAS_HISTORICAL_WEB_PRESENCE:
    case APIRiskName.HAS_LOW_AVERAGE_TIME_ON_SITE:
    case APIRiskName.HAS_LOW_GOOGLE_RESULTS_COUNT:
    case APIRiskName.HAS_LOW_WEBSITE_DEVELOPMENT_ACTIVITY:
    case APIRiskName.HAS_MEANINGFUL_PAID_SEARCH_TRAFFIC:
    case APIRiskName.HAS_MEANINGFUL_TECHNOLOGY_SPEND:
    case APIRiskName.HAS_MEANINGFUL_WEBSITE_DEVELOPMENT_ACTIVITY:
    case APIRiskName.HAS_PROHIBITIVELY_RECENT_REGISTRATION:
    case APIRiskName.HAS_REDIRECTED_DOMAIN:
    case APIRiskName.HAS_ROBOTS_TXT:
    case APIRiskName.HAS_SCRAPING_FAILED:
    case APIRiskName.HAS_SOME_INVALID_EMAILS:
    case APIRiskName.IS_DEFAULT_SOCIAL_MEDIA_LINK_PRESENT:
    case APIRiskName.IS_DOMAIN_REGISTRAR_NEAR_BUSINESS_LOCATION:
    case APIRiskName.IS_HOSTING_PROVIDER_IN_HIGH_RISK_COUNTRY:
    case APIRiskName.IS_HOSTING_PROVIDER_IN_PROHIBITED_COUNTRY:
    case APIRiskName.IS_KNOWN_FREE_EMAIL_HOST:
    case APIRiskName.IS_KNOWN_HOSTING_PROVIDER_DOMAIN:
    case APIRiskName.IS_KNOWN_LINK_SHORTENER_DOMAIN:
    case APIRiskName.IS_KNOWN_MARKETPLACE_DOMAIN:
    case APIRiskName.IS_KNOWN_SOCIAL_MEDIA_DOMAIN:
    case APIRiskName.IS_KNOWN_TOP_DOMAIN:
    case APIRiskName.IS_LONG_REGISTRATION:
    case APIRiskName.IS_NON_BUSINESS_DOMAIN_TLD:
    case APIRiskName.IS_NOT_REGISTERED:
    case APIRiskName.IS_NOT_RESOLVABLE:
    case APIRiskName.IS_PARKED:
    case APIRiskName.IS_PLACEHOLDER_TEXT_PRESENT:
    case APIRiskName.IS_PRIMARY_TRAFFIC_FROM_HIGH_RISK_COUNTRY:
    case APIRiskName.IS_PRIMARY_TRAFFIC_FROM_PROHIBITED_COUNTRY:
    case APIRiskName.IS_REGISTRAR_IN_HIGH_RISK_COUNTRY:
    case APIRiskName.IS_REGISTRAR_IN_PROHIBITED_COUNTRY:
    case APIRiskName.IS_REGISTRATION_EXPIRATION_CONCERNINGLY_SOON:
    case APIRiskName.IS_REGISTRATION_EXPIRATION_PROHIBITIVELY_SOON:
    case APIRiskName.IS_REGISTRATION_EXPIRED:
    case APIRiskName.IS_REGISTRATION_UPDATED_CONCERNINGLY_RECENTLY:
    case APIRiskName.IS_REGISTRATION_UPDATED_PROHIBITIVELY_RECENTLY:
    case APIRiskName.IS_SHORT_REGISTRATION:
    case APIRiskName.IS_SSL_INVALID:
    case APIRiskName.IS_UNDER_CONSTRUCTION:
    case APIRiskName.IS_WEBSERVER_NONRESPONSIVE:
    case APIRiskName.IS_WEBSITE_HOSTING_PROVIDER_NEAR_BUSINESS_LOCATION:
      return TileType.WEBSITE_OPERATIONS;

    case APIRiskName.HAS_ALL_VALID_PHONE_NUMBERS:
    case APIRiskName.HAS_CLOSED_INDICATORS:
    case APIRiskName.HAS_CONCERNINGLY_FEW_EMPLOYEES:
    case APIRiskName.HAS_CONNECTED_ENTITIES:
    case APIRiskName.HAS_DISCOVERABLE_ESTIMATED_ANNUAL_REVENUE:
    case APIRiskName.HAS_DISCOVERABLE_ESTIMATED_EMPLOYEE_RANGE:
    case APIRiskName.HAS_EMAIL_REPORTEDLY_USED_IN_FRAUD:
    case APIRiskName.HAS_FOOT_TRAFFIC_AT_ADDRESS:
    case APIRiskName.HAS_HISTORY_OF_DECEPTIVE_APPLICATION_REPORTS:
    case APIRiskName.HAS_HISTORY_OF_DECEPTIVE_BUSINESS_REPORTS:
    case APIRiskName.HAS_LOW_TECHNOLOGY_SPEND:
    case APIRiskName.HAS_MULTI_LEVEL_MARKETING_NAME:
    case APIRiskName.HAS_OPERATING_HISTORY:
    case APIRiskName.HAS_PERSON_REPORTEDLY_ASSOCIATED_WITH_FRAUD:
    case APIRiskName.HAS_PHONE_REPORTEDLY_USED_IN_FRAUD:
    case APIRiskName.HAS_PROHIBITIVELY_FEW_EMPLOYEES:
    case APIRiskName.HAS_SOCIAL_LINK_REPORTEDLY_USED_IN_FRAUD:
    case APIRiskName.HAS_SUFFICIENT_EMPLOYEES:
    case APIRiskName.HAS_VISIBLE_SIGNAGE_AT_ADDRESS:
    case APIRiskName.IS_ADDRESS_INACTIVE:
    case APIRiskName.IS_ADDRESS_PO_BOX:
    case APIRiskName.IS_ADDRESS_RESIDENTIAL:
    case APIRiskName.IS_ADDRESS_VACANT:
    case APIRiskName.IS_ADDRESS_VIRTUAL:
    case APIRiskName.IS_COMPANY_SIZE_AND_REVENUE_METRICS_ALIGNED:
    case APIRiskName.IS_COMPANY_SIZE_AND_REVENUE_METRICS_NOT_ALIGNED:
    case APIRiskName.IS_COUNTRY_HIGH_RISK:
    case APIRiskName.IS_COUNTRY_PROHIBITED:
    case APIRiskName.IS_FOUNDED_RECENTLY:
    case APIRiskName.IS_PRIMARY_PHONE_NUMBER_INACTIVE:
    case APIRiskName.IS_PRIMARY_PHONE_NUMBER_INVALID:
    case APIRiskName.IS_PRIMARY_PHONE_NUMBER_LINKED_TO_COMPANY:
    case APIRiskName.IS_PRIMARY_PHONE_NUMBER_TYPE_SUSPICIOUS:
    case APIRiskName.IS_REGISTERED_AGENT_ADDRESS:
    case APIRiskName.IS_UNEXPECTED_LANGUAGE:
      return TileType.PHYSICAL_OPERATIONS;

    case APIRiskName.HAS_CONCERNINGLY_RECENT_SUBMITTED_EMAIL:
    case APIRiskName.IS_SUBMITTED_ADDRESS_COUNTRY_HIGH_RISK:
    case APIRiskName.IS_SUBMITTED_ADDRESS_COUNTRY_PROHIBITED:
    case APIRiskName.IS_SUBMITTED_ADDRESS_INACTIVE:
    case APIRiskName.IS_SUBMITTED_ADDRESS_PO_BOX:
    case APIRiskName.IS_SUBMITTED_ADDRESS_RESIDENTIAL:
    case APIRiskName.IS_SUBMITTED_ADDRESS_VACANT:
    case APIRiskName.IS_SUBMITTED_ADDRESS_VIRTUAL:
    case APIRiskName.IS_SUBMITTED_EMAIL_FROM_DISPOSABLE_HOST:
    case APIRiskName.IS_SUBMITTED_EMAIL_FROM_KNOWN_FREE_HOST:
    case APIRiskName.IS_SUBMITTED_EMAIL_INVALID:
    case APIRiskName.IS_SUBMITTED_PHONE_INVALID:
    case APIRiskName.IS_SUBMITTED_PHONE_TYPE_SUSPICIOUS:
    case APIRiskName.IS_SUBMITTED_REGISTERED_AGENT_ADDRESS:
    case APIRiskName.SUBMITTED_ADDRESS_HAS_BUSINESS_CONNECTIONS:
    case APIRiskName.SUBMITTED_DESCRIPTION_DOES_NOT_MATCH_BUSINESS:
    case APIRiskName.SUBMITTED_EMAIL_DOMAIN_DOES_NOT_MATCH_BUSINESS_DOMAIN:
    case APIRiskName.SUBMITTED_EMAIL_HAS_BUSINESS_CONNECTIONS:
    case APIRiskName.SUBMITTED_PERSON_HAS_BUSINESS_CONNECTIONS:
    case APIRiskName.SUBMITTED_PHONE_HAS_BUSINESS_CONNECTIONS:
      return TileType.APPLICATION;

    // don't put a default case here, or you'll break missing case analysis !
  }
};
