import { useState, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import type { BoxProps } from "@mui/material/Box";
import ArchiveIcon from "@mui/icons-material/Archive";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTrueBizApi } from "../../../api";
import { useIdentity } from "../../IdentityProvider";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";
import {
  APIAnomalyActivityType,
  APIAnomalyResolutionType,
} from "../../../types/APIAnomaly";
import { APIMonitoringEnrollment } from "../../../types/APIMonitoringEnrollment";

export interface Props extends BoxProps {
  issueId: string;
  monitorDomain?: string;
  disabled?: boolean;
}

export default function ResolveButton({
  issueId,
  monitorDomain,
  disabled = false,
  ...etc
}: Props) {
  const api = useTrueBizApi();
  const identity = useIdentity();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();

  const [el, setEl] = useState<HTMLElement | null>(null);

  const [selectedResolution, setSelectedResolution] = useState<
    APIAnomalyResolutionType | ""
  >("");
  const [summary, setSummary] = useState("");

  const incomplete = !summary || !selectedResolution;

  const onCancel = useCallback(() => {
    setEl(null);
    setSelectedResolution("");
    setSummary("");
  }, []);

  const resolveMutation = useMutation({
    mutationFn: async () => {
      if (!selectedResolution) {
        throw new Error("Bad resolution type");
      }

      if (!summary) {
        throw new Error("Missing resolution summary");
      }

      return api.resolveAnomaly(issueId, {
        resolutionType: selectedResolution,
        resolutionDescription: summary,
      });
    },
    mutationKey: ["issues", "resolve", issueId],
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["getMonitor", monitorDomain],
      });

      const previousMonitor = queryClient.getQueryData([
        "getMonitor",
        monitorDomain,
      ]);

      const now = new Date().toISOString();

      if (previousMonitor) {
        queryClient.setQueryData(
          ["getMonitor", monitorDomain],
          (old: APIMonitoringEnrollment) => {
            return {
              ...old,
              unresolved_anomaly_count: old.unresolved_anomaly_count - 1,
              anomalies: old.anomalies.map((anomaly) => {
                if (anomaly.id !== issueId) return anomaly;

                return {
                  ...anomaly,
                  resolution: {
                    created_at: now,
                    type: selectedResolution,
                    description: summary,
                    resolved_by: {
                      id: "PENDING",
                      external_ref_id: null,
                      email: identity.user?.email || "A TrueBiz user",
                    },
                  },
                  activity: [
                    ...anomaly.activity,
                    {
                      id: "PENDING",
                      created_at: now,
                      type: APIAnomalyActivityType.RESOLVED,
                      resolution: {
                        created_at: now,
                        type: selectedResolution,
                        description: summary,
                        resolved_by: {
                          id: "PENDING",
                          external_ref_id: null,
                          email: identity.user?.email || "A TrueBiz user",
                        },
                      },
                    },
                  ],
                };
              }),
            };
          }
        );
      }

      return { previousMonitor };
    },
    onError: (err, variables, context) => {
      if (context?.previousMonitor) {
        queryClient.setQueryData(
          ["getMonitor", monitorDomain],
          context.previousMonitor
        );
      }

      // if someone else resolved, refetch so that we show the new resolved status to the user
      queryClient.invalidateQueries({
        queryKey: ["getNotification", issueId],
        refetchType: "all",
      });

      if (monitorDomain) {
        queryClient.invalidateQueries({
          queryKey: ["getMonitor", monitorDomain],
          refetchType: "all",
        });
      }

      showToast();
    },
    onSuccess: async () => {
      setEl(null);
      setSelectedResolution("");
      setSummary("");

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["getNotification", issueId],
        }),

        queryClient.invalidateQueries({
          queryKey: ["my issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["all issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["open issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["my", "issues", "openIssueCount"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["all", "issues", "openIssueCount"],
        }),

        ...(monitorDomain
          ? [
              queryClient.invalidateQueries({
                queryKey: ["getMonitor", monitorDomain],
              }),
            ]
          : []),
      ]);
    },
  });

  const alreadyResolved = false;

  return (
    <Box {...etc}>
      <IconButton
        sx={{
          display: "flex",
          flexShrink: 0,
        }}
        disabled={disabled || alreadyResolved}
        onClick={(e: any) => {
          setEl(e.currentTarget);
        }}
      >
        <ArchiveIcon />
      </IconButton>
      <Popover
        open={!disabled && el != null}
        anchorEl={el}
        onClose={resolveMutation.isPending ? undefined : onCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ padding: "1em", width: "300px" }}>
          <FormControl>
            <FormLabel>Action to resolve this issue (required)</FormLabel>
            <RadioGroup
              value={selectedResolution}
              onChange={(e) => {
                setSelectedResolution(
                  e.target.value as APIAnomalyResolutionType
                );
              }}
            >
              <FormControlLabel
                value={APIAnomalyResolutionType.CLEARED}
                control={<Radio />}
                label="Cleared"
              />
              <FormControlLabel
                value={APIAnomalyResolutionType.TERMINATED}
                control={<Radio />}
                label="Terminated Merchant"
              />
              <FormControlLabel
                value={APIAnomalyResolutionType.NOTICE}
                control={<Radio />}
                label="Notice Sent"
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mt: "1em" }}>
            <FormLabel>Summary of resolution (required)</FormLabel>
            <TextField
              multiline
              rows={3}
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
              }}
              disabled={resolveMutation.isPending}
            />
          </FormControl>
          {/* TODO: display error Alert */}
          <div
            style={{
              display: "flex",
              gap: "1em",
              justifyContent: "end",
              marginTop: "1em",
            }}
          >
            <Button
              variant="outlined"
              disabled={resolveMutation.isPending}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={resolveMutation.isPending}
              disabled={incomplete}
              variant="contained"
              onClick={async () => {
                await resolveMutation.mutateAsync();
              }}
            >
              Resolve Issue
            </LoadingButton>
          </div>
        </Stack>
      </Popover>
    </Box>
  );
}
