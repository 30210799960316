import {
  Typography,
  Modal,
  Paper,
  Box,
  Autocomplete,
  TextField,
  IconButton,
  useTheme,
  lighten,
  darken,
} from "@mui/material";
import * as d3 from "d3";
import { getAlpha2ByAlpha3 } from "country-locale-map";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";

import { countriesData } from "./countriesData";
import {
  EEC_COUNTRY_CODES,
  EU_COUNTRY_CODES,
  OFACS_COUNTRY_CODES,
} from "../constants";
import { APICountry } from "../../../types/APICompanyMatchProfileConstants";
import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

const customAlpha2FromAlpha3 = (alpha3: string) => {
  if (alpha3 === "OSA") {
    return "XK";
  }

  if (alpha3 === "-99") {
    return "CY";
  }

  const alpha2 = getAlpha2ByAlpha3(alpha3);

  if (!alpha2) {
    throw new Error("no alpha2 for alpha3 " + alpha3);
  }

  return alpha2;
};

const Map = ({
  width,
  height,
  matchProfile,
  cycleCountryCode,
}: {
  width: number;
  height: number;
  matchProfile: APICompanyMatchProfile;
  cycleCountryCode: (countryCode: string) => void;
}) => {
  const theme = useTheme();

  const projection = d3
    .geoMercator()
    .scale(width / 2 / Math.PI - 40)
    .center([-30, 10]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const handleClick = (evt: any) => {
    const alpha3 = evt.target.getAttribute("id");
    const alpha2 = customAlpha2FromAlpha3(alpha3);
    cycleCountryCode(alpha2);
  };

  const getCountryColor = (alpha3: string) => {
    const alpha2 = customAlpha2FromAlpha3(alpha3);

    if (matchProfile.red_country_codes.includes(alpha2)) {
      return darken(theme.palette.error.dark, 0.2);
    }

    if (matchProfile.yellow_country_codes.includes(alpha2)) {
      return theme.palette.warning.light;
    }

    return darken(theme.palette.success.dark, 0.5);
  };

  const allSvgPaths = countriesData.features
    .filter((shape: any) => shape.id !== "ATA")
    .map((shape: any, index: number) => {
      const fill = getCountryColor(shape.id);
      return (
        <path
          onClick={handleClick}
          key={shape.id + index}
          d={geoPathGenerator(shape) || undefined}
          stroke="lightGrey"
          strokeWidth={0.5}
          fill={fill}
          fillOpacity={0.7}
          id={shape.id}
        />
      );
    });

  return (
    <Box sx={{ border: "1px solid grey" }}>
      <svg width={width} height={height}>
        {allSvgPaths}
      </svg>
    </Box>
  );
};

function CountrySelect({
  updateCountryCodes,
  countries,
  assignGroup,
}: {
  updateCountryCodes: any;
  countries: APICountry[];
  assignGroup: string;
}) {
  const options = countries.map((c: any) => ({ label: c.name, id: c.code }));
  options.unshift({ label: "European Econonomic Community", id: "eec" });
  options.unshift({ label: "European Union", id: "europeanunion" });
  options.unshift({ label: "OFACS Sanctioned Countries", id: "ofacs" });
  options.unshift({ label: "South America", id: "southamerica" });
  options.unshift({ label: "Oceana", id: "oceana" });
  options.unshift({ label: "North America", id: "northamerica" });
  options.unshift({ label: "Europe", id: "europe" });
  options.unshift({ label: "Asia", id: "asia" });
  options.unshift({ label: "Africa", id: "africa" });
  options.unshift({ label: "All Countries", id: "allcountries" });

  const africaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "africa";
    })
    .map((country) => {
      return country.code;
    });
  africaCountryCodes.push("SS");

  const northAmericaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "north america";
    })
    .map((country) => {
      return country.code;
    });
  const southAmericaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "south america";
    })
    .map((country) => {
      return country.code;
    });
  const europeCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "europe";
    })
    .map((country) => {
      return country.code;
    });
  const asiaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "asia";
    })
    .map((country) => {
      return country.code;
    });
  const oceaniaCountryCodes = countries
    .filter((country) => {
      return country.continent.toLowerCase() === "oceania";
    })
    .map((country) => {
      return country.code;
    });
  const allCountryCodes = countries.map((c) => c.code);
  allCountryCodes.push("SS");

  const handleSelect = (optionId: string) => {
    switch (optionId) {
      case "allcountries": {
        updateCountryCodes(
          countries.map((c) => c.code),
          assignGroup
        );
        break;
      }
      case "eec": {
        updateCountryCodes(EEC_COUNTRY_CODES, assignGroup);
        break;
      }
      case "ofacs": {
        updateCountryCodes(OFACS_COUNTRY_CODES, assignGroup);
        break;
      }
      case "europeanunion": {
        updateCountryCodes(EU_COUNTRY_CODES, assignGroup);
        break;
      }
      case "africa": {
        updateCountryCodes(africaCountryCodes, assignGroup);
        break;
      }
      case "europe": {
        updateCountryCodes(europeCountryCodes, assignGroup);
        break;
      }
      case "northamerica": {
        updateCountryCodes(northAmericaCountryCodes, assignGroup);
        break;
      }
      case "southamerica": {
        updateCountryCodes(southAmericaCountryCodes, assignGroup);
        break;
      }
      case "asia": {
        updateCountryCodes(asiaCountryCodes, assignGroup);
        break;
      }
      case "oceana": {
        updateCountryCodes(oceaniaCountryCodes, assignGroup);
        break;
      }
      default: {
        updateCountryCodes([optionId], assignGroup);
        break;
      }
    }
  };

  return (
    <Box display="inline-block" width="300px">
      <Autocomplete
        onChange={(evt, value) => handleSelect(value?.id)}
        size="small"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Box>
  );
}

export default function LocationModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
  countries,
  readOnly,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: APICompanyMatchProfile;
  updateMatchProfile: any;
  countries: APICountry[];
  readOnly: boolean;
}) {
  const theme = useTheme();

  const cycleCountryCode = (code: string) => {
    if (matchProfile.red_country_codes.includes(code)) {
      updateCountryCodes([code], "allowed");
    } else if (matchProfile.yellow_country_codes.includes(code)) {
      updateCountryCodes([code], "red");
    } else {
      updateCountryCodes([code], "yellow");
    }
  };

  const updateCountryCodes = (countryCodes: string[], newGroup: string) => {
    if (newGroup === "allowed") {
      updateMatchProfile({
        red_country_codes: [...matchProfile.red_country_codes].filter(
          (rowCc) => !countryCodes.includes(rowCc)
        ),
        yellow_country_codes: [...matchProfile.yellow_country_codes].filter(
          (rowCc) => !countryCodes.includes(rowCc)
        ),
      });
    } else if (newGroup === "red") {
      const newRedUndeduped = [
        ...countryCodes,
        ...matchProfile.red_country_codes,
      ];

      updateMatchProfile({
        red_country_codes: [...new Set(newRedUndeduped)],
        yellow_country_codes: [...matchProfile.yellow_country_codes].filter(
          (rowCc) => !countryCodes.includes(rowCc)
        ),
      });
    } else {
      const newYellowUndeduped = [
        ...countryCodes,
        ...matchProfile.yellow_country_codes,
      ];

      updateMatchProfile({
        red_country_codes: [...matchProfile.red_country_codes].filter(
          (rowCc) => !countryCodes.includes(rowCc)
        ),
        yellow_country_codes: [...new Set(newYellowUndeduped)],
      });
    }
  };

  const getPillColor = (countryCode: string) => {
    if (matchProfile.red_country_codes.includes(countryCode)) {
      return lighten(theme.palette.error.light, 0.5);
    }

    if (matchProfile.yellow_country_codes.includes(countryCode)) {
      return lighten(theme.palette.warning.light, 0.5);
    }

    return lighten(theme.palette.success.light, 0.5);
  };

  const gather: any = {};
  countries.forEach((country: any) => {
    if (!(country.continent in gather)) {
      gather[country.continent] = [];
    }
    gather[country.continent].push(country);
  });
  const continents = Object.keys(gather);
  continents.sort();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Countries
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ mb: 3, color: grey[800], fontStyle: "italic" }}
            >
              Set countries to "Prohibited" (red) if you want a business to fail
              if the business's primary location is in this country. Set to
              "High Risk" (yellow) if you would like to be alerted and have this
              finding contribute to a business failure recommendation. Set to
              "Allowed" (green) to ignore a business's association with a
              country.
            </Typography>

            {!readOnly && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ ml: 3 }}>
                  <Typography variant="h6">Set Countries to Allowed</Typography>
                  <CountrySelect
                    updateCountryCodes={updateCountryCodes}
                    countries={countries}
                    assignGroup="allowed"
                  />
                </Box>

                <Box>
                  <Typography variant="h6">
                    Set Countries to High Risk
                  </Typography>
                  <CountrySelect
                    updateCountryCodes={updateCountryCodes}
                    countries={countries}
                    assignGroup="yellow"
                  />
                </Box>

                <Box sx={{ mr: 3 }}>
                  <Typography variant="h6">
                    Set Countries to Prohibited
                  </Typography>
                  <CountrySelect
                    updateCountryCodes={updateCountryCodes}
                    countries={countries}
                    assignGroup="red"
                  />
                </Box>
              </Box>
            )}

            <Box sx={{ mt: 4, mb: 4 }}>
              <Map
                width={1200}
                height={400}
                matchProfile={matchProfile}
                cycleCountryCode={readOnly ? () => null : cycleCountryCode}
              />
            </Box>

            <Box
              sx={{
                height: "100%",
                "white-space": "pre-wrap",
              }}
            >
              {continents.map((continent: string) => (
                <Box key={continent}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      display="inline-block"
                      sx={{ fontWeight: 600, mr: 1 }}
                    >
                      {continent}
                    </Typography>
                  </Box>
                  {gather[continent].toSorted().map((country: any) => (
                    <Box
                      key={country.code}
                      display={"inline-block"}
                      sx={{
                        backgroundColor: getPillColor(country.code),
                        borderRadius: 2,
                        m: 0.5,
                        pt: 0,
                        pb: 0,
                        pr: 1,
                        pl: 1,
                        cursor: "pointer",
                        fontWeight: 800,
                        userSelect: "none",
                        "&:hover": { filter: "brightness(0.75)" },
                      }}
                      onClick={() =>
                        !readOnly && cycleCountryCode(country.code)
                      }
                    >
                      <Typography variant="body2">{country.name}</Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
