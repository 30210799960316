import type { SvgIconProps } from "@mui/material";
import {
  AccountBalanceOutlined,
  MoneyOff,
  ReportGmailerrorredOutlined,
  ErrorOutlined,
} from "@mui/icons-material";

import { APINewsArticleType } from "../../../../types/APINewsArticleType";

interface Props extends SvgIconProps {
  flag: APINewsArticleType | string;
}

export default function FlaggedConcernIcon({ flag, ...etc }: Props) {
  switch (flag) {
    case APINewsArticleType.BANKRUPTCY:
      return <MoneyOff {...etc} />;
    case APINewsArticleType.FRAUD:
      return <ReportGmailerrorredOutlined {...etc} />;
    case APINewsArticleType.LEGAL:
      return <AccountBalanceOutlined {...etc} />;
    default:
      return <ErrorOutlined {...etc} />;
  }
}
