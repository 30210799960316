import { useCallback, useMemo, ReactNode } from "react";
import {
  AuthProvider as PropelAuthProvider,
  RedirectToLogin,
  useAuthInfo,
  useLogoutFunction,
  useRedirectFunctions,
} from "@propelauth/react";
import { CircularProgress } from "@mui/material";

import { Identity } from "../types";
import { IdentityContext, AuthenticationRedirectContext } from "../context";

// This is awkward, but it has to be in a separate component for all the
// context consumers to work correctly.
function PropelAuthConsumer({ children }: { children: ReactNode }) {
  const authInfo = useAuthInfo();
  const loading = authInfo.loading;
  const isAuthenticated = authInfo.isLoggedIn;
  const accessToken = authInfo.accessToken;
  const email = authInfo.user?.email;
  const accountName = authInfo.orgHelper?.getOrgs()[0]?.orgName;
  const isImpersonating = authInfo.isImpersonating;

  const doLogout = useLogoutFunction();
  const { redirectToLoginPage, redirectToAccountPage } = useRedirectFunctions();

  const getAuthorizationHeaders = useMemo(() => {
    if (!accessToken) return () => ({});
    return () => ({ Authorization: `Bearer ${accessToken}` });
  }, [accessToken]);

  const logout = useCallback(async () => {
    doLogout(true);
  }, [doLogout]);

  const login = useCallback(async () => {
    redirectToLoginPage();
  }, [redirectToLoginPage]);

  const settings = useCallback(async () => {
    redirectToAccountPage();
  }, [redirectToAccountPage]);

  const api: Identity = useMemo(
    () => ({
      loading,
      isAuthenticated: !!isAuthenticated,
      getAuthorizationHeaders,
      logout,
      login,
      settings,
      user: { email, accountName },
      isImpersonating,
    }),
    [
      loading,
      isAuthenticated,
      logout,
      login,
      settings,
      getAuthorizationHeaders,
      email,
      accountName,
      isImpersonating,
    ]
  );

  return (
    <IdentityContext.Provider value={api}>{children}</IdentityContext.Provider>
  );
}

export default function PropelAuthIdentity({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <PropelAuthProvider
      authUrl={process.env.REACT_APP_AUTH_URL || ""}
      defaultDisplayWhileLoading={<CircularProgress />}
    >
      <AuthenticationRedirectContext.Provider value={RedirectToLogin}>
        <PropelAuthConsumer>{children}</PropelAuthConsumer>
      </AuthenticationRedirectContext.Provider>
    </PropelAuthProvider>
  );
}
