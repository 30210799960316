import { useState, ReactElement, useEffect } from "react";

import { ReportOutlined, TravelExplore } from "@mui/icons-material";

import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { difference, isNil, sortBy, uniq } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import OverallAnalysis from "./OverallAnalysis";
import Policy from "./Policy";
import { FilledSourceIcon } from "./SourceTypeIcons";
import {
  getHasSuspiciousContentForPolicy,
  getLegacyHasSuspiciousContentForPolicyType,
  getLegacyPolicyLinkForPolicyType,
  getPrettyLongPolicyName,
  getPrettyShortPolicyName,
} from "./utils";
import PrintOnly from "../../PrintOnly";
import WebOnly from "../../WebOnly";
import APIWebsiteContent from "../../../types/APIWebsiteContent";
import { APIPolicyAnalysisSourceType } from "../../../types/APIPolicyAnalysis";

export interface Props {
  websiteContent: APIWebsiteContent | null;
}

const getUniqueSortableComponentKey = (sortKey: string) =>
  `${sortKey}__${performance.now().toString().replace(".", "")}`;

const getUniqueSortableComponentKeySearchRegex = (search: string) =>
  new RegExp(`.+${search}__\\d+$`);

export default function WebsitePolicyAnalysis({ websiteContent }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    const historicalIndex =
      location.state?.currentWebsitePolicyAnalysisTab || 0;
    if (typeof historicalIndex !== "number") return;
    if (currentTabIndex !== historicalIndex) {
      setCurrentTabIndex(historicalIndex);
    }
  }, [location.state?.currentWebsitePolicyAnalysisTab, currentTabIndex]);

  const policyAnalysis = websiteContent?.policy_analysis;

  const presentContent: ReactElement[] = [];
  const missingContent: ReactElement[] = [];

  const presentTabs: ReactElement[] = [];
  const missingTabs: ReactElement[] = [];

  const onSelectPolicyByURL = (url: string | null | undefined) => {
    if (!url) return;
    const keys = [
      ...sortBy(presentContent, "key"),
      ...sortBy(missingContent, "key"),
    ].map((x) => x.key);

    const newTabIndex = keys.findIndex((v) => {
      if (!v) return false;
      return (
        `${v}`.match(getUniqueSortableComponentKeySearchRegex(url)) !== null
      );
    });

    if (newTabIndex === -1) return;

    navigate(
      {
        pathname: ".",
        hash: window.location.hash || undefined,
        search: window.location.search || undefined,
      },
      { state: { currentWebsitePolicyAnalysisTab: newTabIndex } }
    );
    setCurrentTabIndex(newTabIndex);
  };

  if (
    policyAnalysis?.analyses &&
    policyAnalysis?.analyses.length &&
    policyAnalysis.analyses.filter((analysis) => analysis.answer != null).length
  ) {
    const sortKey = "0_overall";

    presentContent.push(
      <OverallAnalysis
        key={getUniqueSortableComponentKey(sortKey)}
        analyses={policyAnalysis?.analyses}
        allPolicySources={policyAnalysis?.sources}
        onSelectPolicyByURL={onSelectPolicyByURL}
      />
    );

    presentTabs.push(
      <Tab
        key={getUniqueSortableComponentKey(sortKey)}
        label="Analysis"
        icon={<TravelExplore />}
        iconPosition="start"
        sx={{ justifyContent: "flex-start" }}
      />
    );
  }

  if (policyAnalysis?.sources) {
    for (const source of policyAnalysis?.sources || []) {
      const sortKey = `${getPrettyShortPolicyName(
        source.title || source.type
      )}__${source.url}`;

      presentContent.push(
        <Policy
          key={getUniqueSortableComponentKey(sortKey)}
          policy={source}
          relatedAnalyses={policyAnalysis?.analyses?.filter((analysis) => {
            if (!analysis.answer) return false;
            return analysis.sources.includes(source.url || "");
          })}
          allPolicySources={policyAnalysis?.sources || []}
          onSelectPolicyByURL={onSelectPolicyByURL}
          hasSuspiciousContent={getLegacyHasSuspiciousContentForPolicyType(
            websiteContent,
            source.type
          )}
        />
      );

      const hasSuspiciousContent = [
        getLegacyHasSuspiciousContentForPolicyType(websiteContent, source.type),
        getHasSuspiciousContentForPolicy(source),
      ]
        .filter((x) => !isNil(x))
        .reduce((acc, x) => acc || x, false);

      presentTabs.push(
        <Tab
          key={getUniqueSortableComponentKey(sortKey)}
          label={
            <>
              {getPrettyShortPolicyName(source.title || source.type)}
              {hasSuspiciousContent && (
                <ReportOutlined
                  fontSize="small"
                  sx={{ ml: 0.5 }}
                  color="error"
                />
              )}
            </>
          }
          icon={<FilledSourceIcon type={source.type} />}
          iconPosition="start"
          sx={{ justifyContent: "flex-start" }}
        />
      );
    }
  }

  const allKnownPolicyTypes: APIPolicyAnalysisSourceType[] = [
    "cancellation",
    "privacy",
    "refunds",
    "returns",
    "shipping",
    "tos",
  ];

  const allReturnedPolicyTypes = (policyAnalysis?.sources || []).map(
    (source) => source.type
  );

  // since Set.Prototype.difference is not widely supported
  const missingPolicyTypes = uniq(
    difference(allKnownPolicyTypes, allReturnedPolicyTypes)
  );

  for (const missingPolicyType of missingPolicyTypes) {
    const sortKey = getPrettyShortPolicyName(missingPolicyType);

    const legacyURL = getLegacyPolicyLinkForPolicyType(
      websiteContent,
      missingPolicyType
    );

    const synopsis = legacyURL ? (
      <Typography variant="body1" component="div">
        TrueBiz detected a{" "}
        {getPrettyLongPolicyName(missingPolicyType).toLowerCase()}.
      </Typography>
    ) : (
      <Typography
        variant="body1"
        sx={{ color: theme.palette.grey[500] }}
        component="div"
      >
        TrueBiz did not detect a{" "}
        {getPrettyLongPolicyName(missingPolicyType).toLowerCase()} document. A{" "}
        {getPrettyLongPolicyName(missingPolicyType).toLowerCase()} may still be
        present in other policy documents.
      </Typography>
    );

    (legacyURL ? presentContent : missingContent).push(
      <Policy
        key={getUniqueSortableComponentKey(sortKey)}
        policy={{
          type: missingPolicyType,
          url: legacyURL || null,
          title: null,
          language: null,
          last_updated: null,
          company_name: null,
          change_policy: null,
          has_placeholder_text: null,
          policy_contact: null,
          synopsis: synopsis as any,
        }}
        hasSuspiciousContent={
          getLegacyHasSuspiciousContentForPolicyType(
            websiteContent,
            missingPolicyType
          ) || (legacyURL ? false : undefined)
        }
        showFacts={!!legacyURL}
        hideEmptyFacts={!!legacyURL}
        relatedAnalyses={undefined}
        allPolicySources={policyAnalysis?.sources || []}
        onSelectPolicyByURL={onSelectPolicyByURL}
      />
    );

    (legacyURL ? presentTabs : missingTabs).push(
      <Tab
        key={getUniqueSortableComponentKey(sortKey)}
        label={getPrettyShortPolicyName(missingPolicyType)}
        icon={<FilledSourceIcon type={missingPolicyType} />}
        iconPosition="start"
        sx={{ justifyContent: "flex-start", opacity: legacyURL ? 1 : 0.5 }}
      />
    );
  }

  if (!presentTabs.length) {
    return (
      <Box>
        <Typography variant="body1" color={theme.palette.grey[600]}>
          TrueBiz did not detect any website policies.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <WebOnly>
        <Grid container columnGap={2}>
          <Grid item xs={3}>
            <Tabs
              orientation="vertical"
              value={currentTabIndex}
              onChange={(_: any, newTabIndex: any) => {
                navigate(
                  {
                    pathname: ".",
                    hash: window.location.hash || undefined,
                    search: window.location.search || undefined,
                  },
                  {
                    state: { currentWebsitePolicyAnalysisTab: newTabIndex },
                  }
                );
                setCurrentTabIndex(newTabIndex);
              }}
              aria-label="Website Policy Documents"
            >
              {sortBy(presentTabs, "key")}
              {sortBy(missingTabs, "key")}
            </Tabs>
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ pl: 1 }}>
              {[
                ...sortBy(presentContent, "key"),
                ...sortBy(missingContent, "key"),
              ][currentTabIndex] || null}
            </Box>
          </Grid>
        </Grid>
      </WebOnly>
      <PrintOnly>
        <Grid container sx={{ mb: "2em" }}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.grey[600] }}
              component="div"
            >
              Policies Found
            </Typography>
            <Box>
              {policyAnalysis?.sources?.length ? (
                sortBy(policyAnalysis.sources, [
                  ({ title, type }) => getPrettyShortPolicyName(title || type),
                ]).map(({ title, type }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25em",
                    }}
                    key={`${title}__${type}`}
                  >
                    <FilledSourceIcon type={type} />
                    <Typography fontWeight={600}>
                      {getPrettyShortPolicyName(title || type)}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box sx={{ color: theme.palette.grey[600] }}>None</Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.grey[600] }}
            >
              Policies Not Found
            </Typography>
            <Box>
              {missingPolicyTypes.length > 0 ? (
                missingPolicyTypes.map((policyType) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25em",
                      color: theme.palette.grey[600],
                    }}
                    key={`missing__${policyType}`}
                  >
                    <FilledSourceIcon type={policyType} />
                    {getPrettyShortPolicyName(policyType)}
                  </Box>
                ))
              ) : (
                <Box sx={{ color: theme.palette.grey[600] }}>None</Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {[...sortBy(presentContent, "key"), ...sortBy(missingContent, "key")]}
      </PrintOnly>
    </Box>
  );
}
