import { Box, Grid, Paper, Typography } from "@mui/material";
import { uniq } from "lodash";
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  VerifiedUser as VerifiedUserIcon,
} from "@mui/icons-material";
import FootTrafficGraph from "./FootTrafficGraph";
import Map from "./Map";
import {
  getDisplayAddress,
  humanizeString,
} from "../../../utilities/formatting";
import Attribute from "../Attribute";
import Tooltip from "../../Tooltip";
import BooleanAttribute from "../BooleanAttribute";
import APIAddress from "../../../types/APIAddress";
import APIPhone from "../../../types/APIPhone";
import APIWebsiteTraffic from "../../../types/APIWebsiteTraffic";
import APIPerson from "../../../types/APIPerson";
import APIDomain from "../../../types/APIDomain";
import APIFootTraffic from "../../../types/APIFootTraffic";

export default function LocationTile({
  primaryAddress,
  primaryPhoneNumber,
  addressProperties,
  websiteTraffic,
  people,
  apiFootTraffic,
  domain,
  hasVisibleSignage = false,
}: {
  primaryAddress: APIAddress | null;
  primaryPhoneNumber?: APIPhone | null | undefined;
  websiteTraffic: APIWebsiteTraffic | null;
  people: APIPerson[] | null;
  domain: APIDomain | null;
  apiFootTraffic: APIFootTraffic | null;
  addressProperties: any;
  hasVisibleSignage?: Boolean;
}) {
  const MAP_HEIGHT_PX = 406;

  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <Typography variant="h6" component="h3" marginBottom={1}>
        Location Data
      </Typography>

      <Box mb={2}>
        <Attribute label="Primary address">
          {primaryAddress ? getDisplayAddress(primaryAddress) : null}
        </Attribute>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FootTrafficGraph
            apiFootTraffic={apiFootTraffic}
            componentHeightPx={MAP_HEIGHT_PX}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box position="relative">
            <Map address={primaryAddress} componentHeightPx={MAP_HEIGHT_PX} />
            {hasVisibleSignage && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: "0.25em",
                  gap: "0.25em",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  marginInline: "auto",
                  width: "fit-content",
                  background: "rgba(255, 255, 255, 0.85)",
                  borderTopRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                }}
              >
                <VerifiedUserIcon color="success" />
                Business signage is visible in street view
                <Tooltip
                  title="Signage may not always be visible from interior location views which are sometimes shown instead of exterior imagery."
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={4}>
          <BooleanAttribute
            label="Valid?"
            labelIcon={
              <Tooltip
                title="Is the primary address known to and confirmed as valid by the postal service?"
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            // only show validity signals, no validation failures
            value={primaryAddress?.is_valid || null}
            trueIsPositive
            failureText="Unknown"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <BooleanAttribute
            label="Is shared with registered agent?"
            labelIcon={
              <Tooltip
                title={
                  addressProperties?.is_shared_with_registered_agent
                    ?.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            value={
              !!primaryAddress && !!primaryAddress?.line_1
                ? primaryAddress?.is_shared_with_registered_agent
                : null
            }
            trueIsPositive={false}
            failureText="Unknown"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Attribute
            label="Primary address postal flags"
            labelIcon={
              <Tooltip
                title={addressProperties?.postal_service_flags?.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            failureText="Unknown"
          >
            {primaryAddress?.postal_service_flags
              ?.map(humanizeString)
              ?.join(", ") || "No Flags Found"}
          </Attribute>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ marginTop: "12px" }} />
        </Grid>

        {websiteTraffic && (
          <Grid item xs={12} md={4}>
            <Attribute label="Primary traffic location">
              {websiteTraffic?.top_country_shares?.[0]?.name}
            </Attribute>
          </Grid>
        )}

        {primaryPhoneNumber &&
          primaryPhoneNumber.country_code &&
          primaryPhoneNumber.country_name && (
            <Grid item xs={12} md={4}>
              <Attribute label="Primary phone location">
                {primaryPhoneNumber.country_name}
              </Attribute>
            </Grid>
          )}

        {!!people && people.length > 0 && (
          <Grid item xs={12} md={4}>
            <Attribute label="People location(s)">
              {uniq((people || []).map((l) => l.country))
                .filter(Boolean)
                .join(", ")}
            </Attribute>
          </Grid>
        )}

        {domain &&
          domain.hosting_providers &&
          domain.hosting_providers.length > 0 && (
            <Grid item xs={12} md={4}>
              <Attribute label="Hosting provider location(s)">
                {uniq(
                  (domain?.hosting_providers || [])
                    .map((l) => l.country)
                    .filter(Boolean)
                ).join(", ")}
              </Attribute>
            </Grid>
          )}

        {domain && domain.registrar && (
          <Grid item xs={12} md={4}>
            <Attribute label="Website registrar location">
              {domain?.registrar?.country}
            </Attribute>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
