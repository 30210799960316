import { ReactNode } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export default function PreviewCard({
  title,
  mainText,
  icon,
  isEdited,
  clickHandler,
  readOnly,
}: {
  title: string;
  mainText: string;
  icon: ReactNode;
  isEdited: boolean;
  clickHandler: () => void;
  readOnly: boolean;
}) {
  const theme = useTheme();

  return (
    <Card
      onClick={clickHandler}
      elevation={2}
      sx={{ height: 180, maxWidth: 550, backgroundColor: grey[100] }}
    >
      <CardActionArea sx={{ height: "100%", p: 2, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            backgroundColor: theme.palette.primary.main,
            height: 30,
            width: 30,
            borderRadius: 1,
            pt: 0.3,
            pl: 0.3,
            color: grey[100],
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h6"
          sx={{ position: "absolute", top: 8, left: 55 }}
        >
          {title}
        </Typography>
        <Divider sx={{ position: "absolute", top: 50, left: 0, right: 60 }} />
        <Box sx={{ position: "absolute", top: 60 }}>
          <Typography variant="subtitle2">{mainText}</Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            position: "absolute",
            bottom: 12,
            right: 12,
            color: theme.palette.primary.main,
          }}
        >
          {readOnly ? "Click to View" : "Click to Edit"}
        </Typography>
        <Typography
          variant="body1"
          sx={{ position: "absolute", bottom: 12, left: 12 }}
        >
          Settings: {isEdited ? "Custom" : "Default"}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
