import { useFormik } from "formik";
import {
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { MonitoringEditFormSchema } from "./schema";
import MonitoringIcon from "./monitoring-icon.svg";
import APICompanyMatchProfile from "../../../../types/APICompanyMatchProfile";
import {
  APIMonitoringEnrollmentInput,
  APIMonitoringEnrollment,
} from "../../../../types/APIMonitoringEnrollment";

export type FormState = ReturnType<
  typeof MonitoringEditFormSchema.validateSync
>;

export interface Props {
  monitor: APIMonitoringEnrollment;
  matchProfiles: APICompanyMatchProfile[];
  onCancel: () => void;
  onSuccess: (domain: string) => void;
  onError: (domain: string, reason?: string, errors?: string[]) => void;
  onEditMonitor: (
    formData: APIMonitoringEnrollmentInput
  ) => Promise<APIMonitoringEnrollment>;
}

export default function MonitorEditForm({
  monitor,
  matchProfiles,
  onCancel,
  onSuccess,
  onError,
  onEditMonitor,
}: Props) {
  const formik = useFormik({
    initialValues: {
      // merchantName: monitor.merchantName || "",
      // user_description: monitor.user_description || "",
      // mcc: monitor.mcc || "",
      external_ref_id: monitor.external_ref_id || "",

      // hidden
      domain: monitor.domain || "",
      profile_id: monitor.risk_profile?.id || "",
    },
    onSubmit: async (values: FormState, { resetForm }) => {
      try {
        await onEditMonitor(values as any);
        resetForm();
        onSuccess(monitor.domain);
      } catch (e) {
        let description = "An unknown error";
        let errors = [];
        try {
          if (e instanceof Response) {
            const j = await e.json();
            description = j?.message || JSON.stringify(j);
            errors =
              j?.errors.flatMap((error: any) => error.errors || []) || [];
          }
        } catch (e) {
          // pass
        }

        onError(values.domain!, description, errors);
      }
    },
    validationSchema: MonitoringEditFormSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* we need to track silently track these since we don't allow partial updates */}
      <input type="hidden" name="domain" />

      <Stack
        sx={{
          alignItems: "center",
          mb: "2em",
        }}
      >
        <img src={MonitoringIcon} width={100} alt="" />
        <Typography align="center">
          Update TrueBiz
          <br />
          merchant monitoring
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: "1em",
        }}
      >
        <FormControl>
          <TextField fullWidth disabled value={monitor.domain} />
        </FormControl>

        {matchProfiles?.length > 0 && (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="match-profile-label"
              shrink
              sx={{ background: "white" }}
            >
              Risk Profile
            </InputLabel>
            <Select
              labelId="match-profile-label"
              label="Risk Profile"
              id="match-profile-select"
              defaultValue={formik.initialValues.profile_id}
              value={formik.values.profile_id ? formik.values.profile_id : ""}
              onChange={formik.handleChange}
              name="profile_id"
              displayEmpty
            >
              <MenuItem value={""}>TrueBiz Default</MenuItem>
              {matchProfiles?.map((profile) => {
                if (profile.id) {
                  return (
                    <MenuItem key={profile.id} value={profile.id}>
                      {profile.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
            {formik.errors.profile_id && formik.touched.profile_id && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.profile_id}
              </Alert>
            )}
          </FormControl>
        )}

        <FormControl>
          <TextField
            fullWidth
            name="external_ref_id"
            label="External Reference ID"
            onChange={formik.handleChange}
            value={formik.values.external_ref_id}
            error={
              !!(
                formik.touched.external_ref_id && formik.errors.external_ref_id
              )
            }
          />
          {formik.errors.external_ref_id && formik.touched.external_ref_id && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.external_ref_id}
            </Alert>
          )}
        </FormControl>

        <Divider />
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "1.5em",
          marginTop: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={formik.isSubmitting}
        >
          Modify Enrollment
        </LoadingButton>
      </div>
    </form>
  );
}
