import { capitalize } from "lodash";
import APIFootTraffic, {
  FootTrafficValue,
} from "../../../../types/APIFootTraffic";

interface ChartSeriesItem {
  x: number;
  y: number;
}

interface ChartSeries {
  name: DayOfWeek;
  data: ChartSeriesItem[];
}

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;
type DayOfWeek = (typeof DAYS_OF_WEEK)[number];

export const getNumericFootTrafficValue = (value: FootTrafficValue): number => {
  switch (value) {
    case FootTrafficValue.Closed:
      return 0;
    case FootTrafficValue.Low:
      return 1;
    case FootTrafficValue.Medium:
      return 2;
    case FootTrafficValue.High:
      return 3;
    case FootTrafficValue.Highest:
      return 4;
    default:
      return 0;
  }
};

export const getDisplayFootTrafficValue = (value: number): string => {
  switch (value) {
    case 0:
      return capitalize(FootTrafficValue.Closed);
    case 1:
      return capitalize(FootTrafficValue.Low);
    case 2:
      return capitalize(FootTrafficValue.Medium);
    case 3:
      return capitalize(FootTrafficValue.High);
    case 4:
      return capitalize(FootTrafficValue.Highest);
    default:
      return capitalize(FootTrafficValue.Closed);
  }
};

export const getFootTrafficScore = (traffic: ChartSeries[]): number => {
  return traffic.reduce((totalSum, series) => {
    const daySum = series.data.reduce((sum, item) => sum + item.y, 0);
    return totalSum + daySum;
  }, 0);
};

export const getFootTrafficData = (
  apiFootTraffic: APIFootTraffic | null
): ChartSeries[] => {
  const rawSeriesData = DAYS_OF_WEEK.map((dayOfWeek) => {
    const raw = apiFootTraffic?.projections.find(
      (d) => d.name.toLowerCase() === dayOfWeek.toLowerCase()
    );

    const hourMap =
      raw?.hours.reduce<Record<number, string>>(
        (acc, { ordinal, occupancy }) => {
          acc[ordinal] = occupancy;
          return acc;
        },
        {}
      ) ?? {};

    const dayData: ChartSeriesItem[] = Array.from({ length: 24 }, (_, hour) => {
      const rawOccupancy = hourMap[hour] ?? FootTrafficValue.Closed;
      return {
        x: hour,
        y: getNumericFootTrafficValue(rawOccupancy as FootTrafficValue),
        // displayHour: format(setHours(new Date(), hour), "h a"),
      };
    });

    return {
      name: dayOfWeek,
      data: dayData,
    };
  });

  return getActiveHoursTraffic(rawSeriesData);
};

function getActiveHoursTraffic(seriesList: ChartSeries[]): ChartSeries[] {
  const dayStats = seriesList.map((day) => {
    const nonZeroItems = day.data.filter((item) => item.y > 0);
    let minHour = Infinity;
    let maxHour = -Infinity;

    for (const item of nonZeroItems) {
      if (item.x < minHour) {
        minHour = item.x;
      }
      if (item.x > maxHour) {
        maxHour = item.x;
      }
    }

    return {
      dayName: day.name,
      nonZeroCount: nonZeroItems.length,
      minHour,
      maxHour,
    };
  });

  let winningDay = dayStats[0];
  for (const ds of dayStats) {
    if (ds.nonZeroCount > winningDay.nonZeroCount) {
      winningDay = ds;
    }
  }

  if (winningDay.nonZeroCount === 0) {
    return seriesList;
  }

  const { minHour, maxHour } = winningDay;
  const expandedMin = Math.max(minHour - 3, 0);
  const expandedMax = Math.min(maxHour + 3, 23);

  return seriesList.map((day) => {
    const filteredData = day.data.filter((item) => {
      if (item.x < expandedMin || item.x > expandedMax) {
        return false;
      }
      return true;
    });

    return {
      ...day,
      data: filteredData,
    };
  });
}
