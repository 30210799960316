import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Alert,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";

import { useParams } from "react-router-dom";
import WebIcon from "@mui/icons-material/Web";
import ArticleIcon from "@mui/icons-material/Article";
import ReviewsIcon from "@mui/icons-material/Reviews";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import HubIcon from "@mui/icons-material/Hub";
import GavelIcon from "@mui/icons-material/Gavel";

import IndustryModal from "./IndustryModal";
import LocationModal from "./LocationModal";
import ContentModal from "./ContentModal";
import TrafficModal from "./TrafficModal";
import SentimentModal from "./SentimentModal";
import PoliciesModal from "./PoliciesModal";
import PreviewCard from "./PreviewCard";
import { useTrueBizApi } from "../../../api";
import {
  APICountry,
  APIMccCode,
} from "../../../types/APICompanyMatchProfileConstants";
import { OFACS_COUNTRY_CODES } from "../constants";
import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

function ActionButton({
  readOnly,
  matchProfileId,
  submitted,
  loading,
  handleSaveProfile,
}: {
  readOnly: boolean;
  matchProfileId: string | undefined;
  submitted: boolean;
  loading: boolean;
  handleSaveProfile: any;
}) {
  if (matchProfileId === "default") {
    return null;
  }

  if (readOnly) {
    return (
      <Button
        sx={{ position: "absolute", top: -55, right: 0 }}
        href={`${window.location.origin}/settings/risk_profile/edit/${matchProfileId}`}
        variant="contained"
        color="primary"
        size="large"
      >
        Edit
      </Button>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      size="large"
      onClick={handleSaveProfile}
      disabled={submitted}
      loading={loading}
      sx={{ position: "absolute", top: -55, right: 0 }}
    >
      SAVE
    </LoadingButton>
  );
}

export default function MatchProfileForm({ readOnly }: { readOnly: boolean }) {
  let { matchProfileId } = useParams();

  const api = useTrueBizApi();
  const [matchProfiles, setMatchProfiles] = useState<
    null | APICompanyMatchProfile[]
  >(null);
  const [parentMatchProfile, setParentMatchProfile] =
    useState<null | APICompanyMatchProfile>(null);
  const [matchProfile, setMatchProfile] =
    useState<null | APICompanyMatchProfile>(null);
  const [openModal, setOpenModal] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [submitted, setSubmitted] = useState(false);
  const [mccData, setMccData] = useState<null | APIMccCode[]>(null);
  const [countries, setCountries] = useState<null | APICountry[]>(null);
  const [contentCategories, setContentCategories] = useState<null | string[]>(
    null
  );
  const [defaultMatchProfile, setDefaultMatchProfile] = useState<null | any>(
    null
  );

  useEffect(() => {
    // call the needed APIs

    const loadMatchProfiles = async () => {
      if (matchProfileId === undefined) {
        return [];
      }
      if (matchProfileId === "default") {
        return [];
      }
      return await api.getCompanyMatchProfiles();
    };

    Promise.all([
      api.getCompanyMatchProfileConstants(),
      loadMatchProfiles(),
    ]).then(([constants, matchProfiles]) => {
      setMccData(constants.mcc_codes);
      setCountries(constants.countries);
      setContentCategories(constants.default_content_categories);
      setMatchProfiles(matchProfiles);
    });
  }, [api, matchProfileId]);

  useEffect(() => {
    // wait for all the API calls to be done, then set MatchProfile and DefaultMatchProfile

    if (
      countries === null ||
      contentCategories === null ||
      mccData === null ||
      matchProfiles === null
    ) {
      return;
    }

    const defaultProfile: APICompanyMatchProfile = {
      id: null,
      status: null,
      created_at: null,
      updated_at: null,
      name: matchProfileId === "default" ? "TrueBiz Default" : "",
      enable_adverse_media: true,
      enable_negative_business_reviews: true,
      enable_paid_search_rate: true,
      enable_time_on_site: true,
      enable_country_traffic: true,
      enable_referring_site: true,
      enable_bounce_rate: true,
      enable_policies: true,
      red_country_codes:
        countries
          ?.filter((country: any) => OFACS_COUNTRY_CODES.includes(country.code))
          .map((country: any) => country.code) || [],
      yellow_country_codes: [],
      red_mcc_codes: [],
      yellow_mcc_codes: [],
      red_content_categories: contentCategories ? [...contentCategories] : [],
      yellow_content_categories: [],
    };
    setDefaultMatchProfile(defaultProfile);

    if (["default", undefined].includes(matchProfileId)) {
      // used if viewing the default or creating a new profile
      setMatchProfile(structuredClone(defaultProfile));
    } else {
      const parentMatchProfile = matchProfiles?.find(
        (mp) => mp.id === matchProfileId
      );
      if (!parentMatchProfile) {
        throw new Error(`unable to find matchProfile ${matchProfileId}`);
      }
      setParentMatchProfile(parentMatchProfile);
      setMatchProfile(parentMatchProfile);
    }
  }, [countries, contentCategories, mccData, matchProfiles, matchProfileId]);

  if (
    !matchProfile ||
    !defaultMatchProfile ||
    !mccData ||
    !countries ||
    !contentCategories
  ) {
    return null;
  }

  const updateMatchProfile = (update: any) => {
    setMatchProfile({ ...matchProfile, ...update });
  };

  const handleSaveProfile = async () => {
    if (!matchProfile) {
      return;
    }

    if (
      [
        isContentEdited(),
        isIndustryEdited(),
        isLocationEdited(),
        isPolicyEdited(),
        isSentimentEdited(),
        isTrafficEdited(),
      ].every((check) => check === false)
    ) {
      setErrorMessage(
        "Nothing has been changed from default settings. Please make a change or use the default risk profile."
      );
      return null;
    }

    if (!matchProfile.name.length) {
      setErrorMessage("Name is required.");
      return null;
    }

    if (parentMatchProfile && parentMatchProfile.name === matchProfile.name) {
      setErrorMessage("A new name is required when changing a risk profile.");
      return null;
    }

    try {
      setSubmitted(true);
      setErrorMessage(null);
      setLoading(true);
      await api.createMatchProfile(matchProfile);
    } catch {
      setSubmitted(false);
      setErrorMessage(
        "Internal error. We have been alerted to the problem and are working on a fix. We apologize for the inconvenience."
      );
    } finally {
      setLoading(false);
    }
  };

  const isIndustryEdited = () => {
    if (
      matchProfile.yellow_mcc_codes.sort().toString() !==
      defaultMatchProfile.yellow_mcc_codes.sort().toString()
    ) {
      return true;
    }
    if (
      matchProfile.red_mcc_codes.sort().toString() !==
      defaultMatchProfile.red_mcc_codes.sort().toString()
    ) {
      return true;
    }

    return false;
  };

  const isContentEdited = () => {
    if (
      matchProfile.yellow_content_categories.sort().toString() !==
      defaultMatchProfile.yellow_content_categories.sort().toString()
    ) {
      return true;
    }
    if (
      matchProfile.red_content_categories.sort().toString() !==
      defaultMatchProfile.red_content_categories.sort().toString()
    ) {
      return true;
    }

    return false;
  };

  const isLocationEdited = () => {
    if (
      matchProfile.yellow_country_codes.sort().toString() !==
      defaultMatchProfile.yellow_country_codes.sort().toString()
    ) {
      return true;
    }
    if (
      matchProfile.red_country_codes.sort().toString() !==
      defaultMatchProfile.red_country_codes.sort().toString()
    ) {
      return true;
    }

    return false;
  };

  const isTrafficEdited = () => {
    return (
      matchProfile.enable_paid_search_rate !==
        defaultMatchProfile.enable_paid_search_rate ||
      matchProfile.enable_time_on_site !==
        defaultMatchProfile.enable_time_on_site ||
      matchProfile.enable_country_traffic !==
        defaultMatchProfile.enable_country_traffic ||
      matchProfile.enable_referring_site !==
        defaultMatchProfile.enable_referring_site ||
      matchProfile.enable_bounce_rate !== defaultMatchProfile.enable_bounce_rate
    );
  };
  const isSentimentEdited = () => {
    return (
      matchProfile.enable_adverse_media !==
        defaultMatchProfile.enable_adverse_media ||
      matchProfile.enable_negative_business_reviews !==
        defaultMatchProfile.enable_negative_business_reviews
    );
  };

  const isPolicyEdited = () => {
    return matchProfile.enable_policies !== defaultMatchProfile.enable_policies;
  };

  const getPageTitle = () => {
    if (!matchProfileId) {
      return "New Risk Profile";
    }

    if (readOnly) {
      return "View Risk Profile";
    }

    return "Edit Risk Profile";
  };

  return (
    <>
      <Typography variant="h4" component="h1" fontWeight={700} gutterBottom>
        {getPageTitle()}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ pb: 4 }}
      >
        <Box
          sx={{ width: "100%", maxWidth: 1150, position: "relative", pt: 5 }}
        >
          {errorMessage && (
            <Alert sx={{ mb: 3 }} severity="error">
              {errorMessage}
            </Alert>
          )}

          {submitted && !loading && !errorMessage && (
            <Alert severity="success" sx={{ mb: 5 }}>
              <Typography variant="h4">Match Profile Created</Typography>
              Your account manager has been notified. They should get back to
              you with information about it within the next business day.
              <Link href={`${window.location.origin}/settings/`}>
                <Typography variant="h6">View Risk Profiles List</Typography>
              </Link>
            </Alert>
          )}
          <ActionButton
            readOnly={readOnly}
            matchProfileId={matchProfileId}
            submitted={submitted}
            loading={loading}
            handleSaveProfile={handleSaveProfile}
          />
          <Box
            sx={{
              height: 30,
              position: "absolute",
              top: -54,
              left: 0,
              right: 0,
              marginInline: "auto",
              width: "fit-content",
              minWidth: 300,
              maxWidth: 800,
            }}
          >
            {readOnly ? (
              <Typography variant="h4">
                <span style={{ color: grey[600] }}>Name: </span>
                {matchProfile.name}
              </Typography>
            ) : (
              <TextField
                disabled={loading}
                label="Risk Profile Name"
                value={matchProfile.name}
                onChange={(e) => updateMatchProfile({ name: e.target.value })}
                type="text"
                sx={{ width: "100%", height: 30 }}
              />
            )}
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("industry")}
                title="Industry Classification"
                mainText="TrueBiz classifies every business using the MCC system. This section allows you to select which MCC categories should be flagged."
                icon={<HubIcon></HubIcon>}
                isEdited={isIndustryEdited()}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("content")}
                title="Content"
                mainText="TrueBiz has a set of flags that are evaluated based on content on a business's website. This section allows configuring which of these you should be alerted to."
                icon={<ArticleIcon></ArticleIcon>}
                isEdited={isContentEdited()}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("location")}
                title="Location"
                mainText="This section allows all countries in the world to be assigned as high risk or prohibited. These settings will alert you based on a business's primary location."
                icon={<EditLocationAltIcon></EditLocationAltIcon>}
                isEdited={isLocationEdited()}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("traffic")}
                title="Traffic"
                mainText="TrueBiz tracks a variety of website traffic data. This section allows you to activate several risks that could flag businesses based on this traffic data."
                icon={<WebIcon></WebIcon>}
                isEdited={isTrafficEdited()}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("sentiment")}
                title="Sentiment"
                mainText="TrueBiz can flag businesses for having poor review sentiment. Configure whether or not this is activated here."
                icon={<ReviewsIcon></ReviewsIcon>}
                isEdited={isSentimentEdited()}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PreviewCard
                clickHandler={() => setOpenModal("policies")}
                title="Policies"
                mainText="TrueBiz can evaluate a business's website policies, such as returns and terms of service. Choose here whether or not this is activated."
                icon={<GavelIcon></GavelIcon>}
                isEdited={isPolicyEdited()}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Box>
        <LocationModal
          open={openModal === "location"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          countries={countries}
          readOnly={readOnly}
        />
        <IndustryModal
          open={openModal === "industry"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          mccData={mccData}
          readOnly={readOnly}
        />
        <ContentModal
          open={openModal === "content"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          contentCategories={contentCategories}
          readOnly={readOnly}
        />
        <TrafficModal
          open={openModal === "traffic"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          readOnly={readOnly}
        />
        <SentimentModal
          open={openModal === "sentiment"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          readOnly={readOnly}
        />
        <PoliciesModal
          open={openModal === "policies"}
          setOpenModal={setOpenModal}
          matchProfile={matchProfile}
          updateMatchProfile={updateMatchProfile}
          readOnly={readOnly}
        />
      </Box>
    </>
  );
}
