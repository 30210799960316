import {
  Typography,
  Modal,
  Paper,
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import { APIMccCode } from "../../../types/APICompanyMatchProfileConstants";
import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

function CategorySummary({
  category,
  gather,
  yellow_mcc_codes,
  red_mcc_codes,
}: {
  category: string;
  gather: any;
  yellow_mcc_codes: string[];
  red_mcc_codes: string[];
}) {
  const rowCount = gather[category].length;
  const yellowCount = gather[category].filter((row: any) =>
    yellow_mcc_codes.includes(row.code)
  ).length;
  const redCount = gather[category].filter((row: any) =>
    red_mcc_codes.includes(row.code)
  ).length;
  const color = yellowCount + redCount > 0 ? "red" : "green";
  const allowedCount = rowCount - yellowCount - redCount;

  return (
    <Box>
      <Typography
        variant="subtitle2"
        component="span"
        sx={{
          mt: 1,
          color: color,
          float: "right",
          width: 500,
          textAlign: "right",
        }}
      >
        <Box sx={{ display: "inline-block", width: 100 }}>
          {allowedCount} Allowed
        </Box>
        <Box sx={{ display: "inline-block", width: 90 }}>
          {yellowCount} High Risk
        </Box>
        <Box sx={{ display: "inline-block", width: 100 }}>
          {redCount} Prohibited
        </Box>
      </Typography>
    </Box>
  );
}

export default function LocationModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
  mccData,
  readOnly,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: APICompanyMatchProfile;
  updateMatchProfile: any;
  mccData: APIMccCode[];
  readOnly: boolean;
}) {
  const categories: any = new Set();
  const gather: any = {};
  mccData.forEach((row) => {
    categories.add(row.category);
    if (!(row.category in gather)) {
      gather[row.category] = [];
    }
    gather[row.category].push(row);
  });

  const setCategoryAllowed = (event: any, category: string) => {
    event.stopPropagation();

    const allowCodes = gather[category].map((row: any) => row.code);
    updateMatchProfile({
      red_mcc_codes: [...matchProfile.red_mcc_codes].filter(
        (mccCode) => !allowCodes.includes(mccCode)
      ),
      yellow_mcc_codes: [...matchProfile.yellow_mcc_codes].filter(
        (mccCode) => !allowCodes.includes(mccCode)
      ),
    });
  };

  const setCategoryYellow = (event: any, category: string) => {
    event.stopPropagation();

    const yellowCodes = gather[category].map((row: any) => row.code);
    const newYellowUndeduped = [
      ...matchProfile.yellow_mcc_codes,
      ...yellowCodes,
    ];
    updateMatchProfile({
      red_mcc_codes: [...matchProfile.red_mcc_codes].filter(
        (mccCode) => !yellowCodes.includes(mccCode)
      ),
      yellow_mcc_codes: [...new Set(newYellowUndeduped)],
    });
  };

  const setCategoryRed = (event: any, category: string) => {
    event.stopPropagation();

    const redCodes = gather[category].map((row: any) => row.code);
    const newRedUndeduped = [...matchProfile.red_mcc_codes, ...redCodes];
    updateMatchProfile({
      red_mcc_codes: [...new Set(newRedUndeduped)],
      yellow_mcc_codes: [...matchProfile.yellow_mcc_codes].filter(
        (mccCode) => !redCodes.includes(mccCode)
      ),
    });
  };

  const updateMccCode = (code: string, newValue: string) => {
    if (newValue === "allowed") {
      updateMatchProfile({
        red_mcc_codes: [...matchProfile.red_mcc_codes].filter(
          (mccCode) => mccCode !== code
        ),
        yellow_mcc_codes: [...matchProfile.yellow_mcc_codes].filter(
          (mccCode) => mccCode !== code
        ),
      });
    } else if (newValue === "yellow") {
      const newYellowUndeduped = [code, ...matchProfile.yellow_mcc_codes];

      updateMatchProfile({
        red_mcc_codes: [...matchProfile.red_mcc_codes].filter(
          (mccCode) => mccCode !== code
        ),
        yellow_mcc_codes: [...new Set(newYellowUndeduped)],
      });
    } else {
      const newRedUndeduped = [code, ...matchProfile.red_mcc_codes];

      updateMatchProfile({
        red_mcc_codes: [...new Set(newRedUndeduped)],
        yellow_mcc_codes: [...matchProfile.yellow_mcc_codes].filter(
          (mccCode) => mccCode !== code
        ),
      });
    }
  };

  const getToggleGroupValue = (mccCode: string) => {
    if (matchProfile.red_mcc_codes.includes(mccCode)) {
      return "red";
    }

    if (matchProfile.yellow_mcc_codes.includes(mccCode)) {
      return "yellow";
    }

    return "allowed";
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Industries
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 3, color: grey[800], fontStyle: "italic" }}
            >
              Set to "Prohibited" if you want a business to fail if the business
              fits this classification. Set to "High Risk" if you would like to
              be alerted and have this finding contribute to a business failure
              recommendation. Set to "Allowed" to ignore this kind of
              classification.
            </Typography>
            {[...categories].map((category: any) => (
              <Accordion key={category}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  key={category}
                  sx={{ position: "relative", width: "100%" }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ display: "inline-block", width: "50%" }}
                  >
                    {category}
                  </Typography>
                  <CategorySummary
                    category={category}
                    gather={gather}
                    yellow_mcc_codes={matchProfile.yellow_mcc_codes}
                    red_mcc_codes={matchProfile.red_mcc_codes}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {!readOnly && (
                    <Box sx={{ mb: 3, mt: -1 }}>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={(event: any) =>
                          setCategoryAllowed(event, category)
                        }
                      >
                        Set All Allowed
                      </Button>

                      <Button
                        size="small"
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={(event: any) =>
                          setCategoryYellow(event, category)
                        }
                      >
                        Set All High Risk
                      </Button>

                      <Button
                        size="small"
                        variant="contained"
                        onClick={(event: any) =>
                          setCategoryRed(event, category)
                        }
                      >
                        Set All Prohibited
                      </Button>
                    </Box>
                  )}

                  {gather[category as keyof typeof gather].map((row: any) => (
                    <Box
                      key={row.code}
                      sx={{ mb: 1, display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "inline-block",
                          width: 255,
                          verticalAlign: "middle",
                        }}
                      >
                        <ToggleButtonGroup
                          value={getToggleGroupValue(row.code)}
                          exclusive
                          onChange={(event: any, newValue: string) =>
                            updateMccCode(row.code, newValue)
                          }
                          size="small"
                          color="primary"
                          sx={{ display: "inline-block", width: 300 }}
                          disabled={readOnly}
                        >
                          <ToggleButton
                            color="success"
                            value="allowed"
                            aria-label="left aligned"
                          >
                            Allowed
                          </ToggleButton>
                          <ToggleButton
                            color="warning"
                            value="yellow"
                            aria-label="centered"
                          >
                            High Risk
                          </ToggleButton>
                          <ToggleButton
                            color="error"
                            value="red"
                            aria-label="right aligned"
                          >
                            Prohibited
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box
                        sx={{
                          display: "inline-block",
                          width: 700,
                          pt: 0,
                          mt: 0,
                        }}
                      >
                        <Typography variant="subtitle1">
                          {row.code} - {row.short_description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
