import { useMemo, useRef, useState } from "react";
import { GraphCanvas, useSelection } from "reagraph";
import type { GraphCanvasRef } from "reagraph";
import {
  useTheme,
  Modal,
  Paper,
  Box,
  Typography,
  Link,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { cross } from "d3-array";
import { countBy } from "lodash";

import { isAlert } from "./utils";

import APIConnectedEntity from "../../../types/APIConnectedEntity";
import ConnectedEntityDetail from "../ConnectedEntityDetail";
import isReadOnly from "../../../utilities/isReadOnly";

export interface Props {
  connectedEntities: APIConnectedEntity[];
  rootEntityNameOrDomain: string;
}

export default function ConnectedEntitiesGraph({
  connectedEntities,
  rootEntityNameOrDomain,
}: Props) {
  const theme = useTheme();
  const graphRef = useRef<GraphCanvasRef | null>(null);
  const [selectedEntity, setSelectedEntity] =
    useState<APIConnectedEntity | null>(null);

  const [nodes, edges] = useMemo(() => {
    const e: any[] = [];

    const n: any[] = [
      {
        id: "root",
        label: rootEntityNameOrDomain,
        data: {},
        size: 20,
        fill: theme.palette.primary.main,
      },
    ];

    const sourceTallies = countBy(
      connectedEntities.flatMap((entity) => entity.sources),
      "type"
    );

    for (const connectedEntity of connectedEntities) {
      const entityId = connectedEntity.domain || connectedEntity.name;
      if (!entityId) {
        continue;
      }

      for (const connectionSource of connectedEntity.sources) {
        const sourceType = connectionSource.type;
        if (!sourceType) {
          continue;
        }

        const nodeId = `${entityId}__${sourceType}`;

        let type: string;
        if (sourceType in sourceTallies && sourceTallies[sourceType] > 1) {
          type = `${sourceType} [${sourceTallies[sourceType]}]`;
        } else {
          type = sourceType;
        }

        n.push({
          id: nodeId,
          label: entityId,
          data: {
            type,
            connectedEntity,
          },
          ...(isAlert(connectedEntity)
            ? { fill: theme.palette.warning.main }
            : {}),
        });

        e.push({
          source: nodeId,
          target: "root",
          id: `${nodeId}->root`,
        });
      }

      if (connectedEntity.sources.length > 1) {
        // the sources should also all have edges between themselves
        for (const [a, b] of cross(
          connectedEntity.sources,
          connectedEntity.sources
        )) {
          if (a.type === b.type) {
            continue;
          }

          e.push({
            source: `${entityId}__${a.type}`,
            target: `${entityId}__${b.type}`,
            id: `${entityId}__${a.type}->${entityId}__${b.type}`,
          });
        }
      }
    }

    return [n, e];
  }, [
    rootEntityNameOrDomain,
    connectedEntities,
    theme.palette.primary.main,
    theme.palette.warning.main,
  ]);

  const { selections, onNodeClick, onCanvasClick, clearSelections } =
    useSelection({
      ref: graphRef,
      nodes,
      edges,
      type: "single",
    });

  return (
    <>
      <div style={{ position: "relative", height: "500px", minWidth: "450px" }}>
        <GraphCanvas
          ref={graphRef}
          nodes={nodes}
          edges={edges}
          clusterAttribute="type"
          edgeArrowPosition="none"
          selections={selections}
          onNodeClick={(node) => {
            if (node.id === "root") return;
            if (node.data.connectedEntity) {
              setSelectedEntity(node.data.connectedEntity);
            }
            onNodeClick?.(node);
          }}
          onCanvasClick={onCanvasClick}
        />
        <Stack position="absolute" bottom={0} left={0} direction="row">
          <IconButton
            title="Zoom graph out"
            onClick={() => {
              graphRef.current?.zoomOut();
            }}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            title="Center graph in view"
            onClick={() => {
              graphRef.current?.fitNodesInView();
            }}
          >
            <CenterFocusStrongIcon />
          </IconButton>
          <IconButton
            title="Zoom graph in"
            onClick={() => {
              graphRef.current?.zoomIn();
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </Stack>
      </div>
      {selectedEntity && (
        <Modal
          open
          onClose={() => {
            setSelectedEntity(null);
            clearSelections();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            modal: {
              "&:focus": {
                outline: "none",
              },
            },
          }}
          disableAutoFocus={true}
        >
          <Paper
            elevation={2}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box marginBottom={4} position="relative">
              <Typography variant="h6" component="h3">
                Connected Entity Detail
              </Typography>
              {selectedEntity.domain && !isReadOnly() && (
                <Link
                  href={`${window.location.origin}/domain/${selectedEntity.domain}?run=1`}
                  target="_blank"
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <Button variant="contained">
                    run full web presence review
                  </Button>
                </Link>
              )}
            </Box>
            <ConnectedEntityDetail connectedEntity={selectedEntity} />
          </Paper>
        </Modal>
      )}
    </>
  );
}
