import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { LoadingButton, Masonry } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Link,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

import Attribute from "./Attribute";
import ContactInfo from "./ContactInfo";
import ConnectedEntities from "./ConnectedEntities";
import DomainAnalysis from "./DomainAnalysis";
import Findings from "./Findings";
import FindingsV2 from "./Findings/FindingsV2";
import Application from "./Application";
import Firmographics from "./Firmographics";
import DownloadPdfButton from "./DownloadPdfButton";
import KeyPeople from "./KeyPeople";
import ProtectedImageEmbed from "./ProtectedImageEmbed";
import { ProtectedIframeEmbed } from "./ProtectedIframeEmbed";
import SocialMedia from "./SocialMedia";
import WebserverAnalysis from "./WebserverAnalysis";
import WebsiteContent from "./WebsiteContent";
import Ecommerce from "./Ecommerce";
import WebsitePolicyAnalysis from "./WebsitePolicyAnalysis";
import WebsiteTraffic from "./WebsiteTraffic";
import { NewsArticles } from "./NewsArticles";
import EnableMonitoringButton from "./EnableMonitoringButton";
import Industry from "./Industry";
import CustomerReviews from "./CustomerReviews";
import LocationTile from "./LocationV2/LocationTile";
import { useIdentity } from "../IdentityProvider";
import AddDomainModal from "../BlockedDomains/AddDomainModal";
import WebOnly from "../WebOnly";
import { useIsPrint } from "../PrintOnly";
import { humanizeDateString } from "../../utilities/formatting";
import OpenapiSchemaResponse from "../../types/OpenapiSchemaResponse";
import { BusinessAttributeSection } from "../../types/BusinessAttributeSection";
import APIResponse from "../../types/APIResponse";
import APIBlockedDomainInput from "../../types/APIBlockedDomainInput";
import { useTrueBizApi } from "../../api";
import APICompanyLookup from "../../types/APICompanyLookup";
import { DataSource } from "../../types/APIApplication";
import useAdminSettings from "../../utilities/adminSettings";

function AnimatedScrollSection({
  attribute,
  children,
  setHighlightedSection,
  highlightedSection,
}: {
  attribute: BusinessAttributeSection;
  children: ReactNode;
  highlightedSection: BusinessAttributeSection | null;
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
}) {
  return (
    <div id={`section-${attribute}`}>
      <motion.div
        onAnimationComplete={() => {
          setHighlightedSection(null);
        }}
        animate={
          highlightedSection === attribute
            ? {
                scale: [1, 1.1, 1],
              }
            : {}
        }
        transition={{
          duration: 0.8,
          ease: "easeInOut",
          delay: 1,
          repeat: 0,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export function BusinessAttributesWithoutDataLoader({
  schema,
}: {
  schema: OpenapiSchemaResponse | null;
}) {
  const api = useTrueBizApi();
  const identity = useIdentity();
  const isPrint = useIsPrint();
  const [settings] = useAdminSettings();

  const {
    response,
    lookup,
    onSubmitLookup,
    domain,
    relayQueryParameters,
    relayRequestId,
  } = useOutletContext<{
    response: APIResponse | null;
    lookup: APICompanyLookup | null;
    onSubmitLookup: (domain: string | null) => void;
    domain: string | null;
    relayQueryParameters: URLSearchParams | null;
    relayRequestId: string | null;
  }>();
  const navigate = useNavigate();
  const theme = useTheme();

  const [showAddBlockedDomainForm, setShowAddBlockedDomainForm] =
    useState<boolean>(false);
  const [blockedDomainLoading, setBlockedDomainLoading] =
    useState<boolean>(false);

  const [highlightedSection, setHighlightedSection] =
    useState<BusinessAttributeSection | null>(null);

  useEffect(() => {
    if (highlightedSection) {
      const el = document.getElementById(`section-${highlightedSection}`);

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [highlightedSection]);

  const websiteContentProperties =
    schema?.components?.schemas?.WebsiteContent?.properties;
  const industryProperties = schema?.components?.schemas?.Industry?.properties;
  const ecommerceProperties =
    schema?.components?.schemas?.Ecommerce?.properties;
  const domainProperties = schema?.components?.schemas?.Domain?.properties;
  const addressProperties = schema?.components?.schemas?.Address?.properties;
  const websiteTrafficProperties =
    schema?.components?.schemas?.WebsiteTraffic?.properties;

  // Only render this component if we're certain that the response
  // was successful and contains a company payload, not an error:
  if (!response || !("name" in response)) {
    return null;
  }

  const referralScreenshots = response.website_traffic?.top_referring_sites
    ? response.website_traffic.top_referring_sites.filter(
        (topRef) => topRef.traffic_share >= 0.25
      )
    : [];

  const ogImageLink =
    response.website_content && response.website_content.opengraph_image_link;

  const handleAddBlockedDomain = async (formData: APIBlockedDomainInput) => {
    try {
      setBlockedDomainLoading(true);
      await api.createBlockedDomain(formData);

      onSubmitLookup?.(formData.domain as string);
      setShowAddBlockedDomainForm(false);
    } finally {
      setBlockedDomainLoading(false);
    }
  };

  return (
    <Box marginTop={3} marginBottom={3}>
      {response.is_blocked && (
        <Box marginBottom={2} marginTop={1}>
          <Alert
            severity="error"
            action={
              <Button
                onClick={() => {
                  navigate("/blocked");
                }}
                size="small"
                variant="outlined"
                color="error"
              >
                Review blocklist
              </Button>
            }
          >
            <AlertTitle>
              You blocked this domain
              {response.block_details
                ? ` on ${humanizeDateString(response.block_details.created_at)}`
                : ""}
              , TrueBiz can&rsquo;t analyze its web presence
            </AlertTitle>
            {response.block_details?.explanation}
          </Alert>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Stack>
            <Typography variant="h5" fontWeight={600} component="h2">
              {response.name || "Unknown company name"}
            </Typography>
            {response.external_tracking_ref && (
              <Typography
                variant="subtitle2"
                style={{ color: theme.palette.grey[700] }}
              >
                Reference ID {response.external_tracking_ref}
              </Typography>
            )}
          </Stack>
        </Box>

        <WebOnly>
          <Stack alignItems="end" gap="0.5em">
            <Box>
              {lookup?.created_at && (
                <>
                  Search run{" "}
                  <strong>{humanizeDateString(lookup.created_at)}</strong>
                </>
              )}
            </Box>
            {identity.isAuthenticated && (
              <Box>
                <LoadingButton
                  onClick={() => setShowAddBlockedDomainForm(true)}
                  sx={{
                    marginLeft: 6,
                  }}
                  variant="outlined"
                  size="small"
                  disabled={
                    !domain ||
                    domain === "offline" ||
                    blockedDomainLoading ||
                    Boolean(response.is_blocked)
                  }
                  loading={blockedDomainLoading}
                >
                  Add to Blocklist
                </LoadingButton>

                <EnableMonitoringButton
                  disabled={!domain || domain === "offline"}
                  domain={domain || "offline"}
                />
                <DownloadPdfButton response={response} sx={{ marginLeft: 2 }} />
              </Box>
            )}
          </Stack>
        </WebOnly>
      </Box>

      <Grid container spacing={2} marginTop={1} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={8}>
          {settings.useV2WPRFindings === true ||
          ["Coast"].includes(identity.user?.accountName || "") ? (
            <FindingsV2
              trackingId={response.tracking_id}
              risks={response.risks}
              setHighlightedSection={setHighlightedSection}
              recommendation={
                response && "recommendation" in response
                  ? response.recommendation
                  : null
              }
            />
          ) : (
            <Findings
              trackingId={response.tracking_id}
              risks={response.risks}
              setHighlightedSection={setHighlightedSection}
              recommendation={
                response && "recommendation" in response
                  ? response.recommendation
                  : null
              }
            />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {response.domain?.screenshot?.fullpage_image_link && (
            <Box
              overflow="auto"
              maxHeight={isPrint ? undefined : 550}
              borderRadius={1}
              boxShadow={2}
            >
              <ProtectedImageEmbed
                src={response.domain?.screenshot?.fullpage_image_link}
                relayQueryParameters={relayQueryParameters}
                relayRequestId={relayRequestId}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: 5, marginBottom: 5 }} />

      <Box mb={3}>
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.Profile}
        >
          <Firmographics
            summary={response.description}
            opengraphImageLink={ogImageLink}
            foundedYear={response?.founded_year}
            metrics={response?.metrics}
            formationType={response?.formation_type}
          />
        </AnimatedScrollSection>
      </Box>

      <Box mb={3}>
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.Application}
        >
          <Application
            application={response?.application}
            primary_phone_number={response?.primary_phone_number}
            additional_phone_numbers={response?.additional_phone_numbers}
            primary_address={response?.primary_address}
            additional_addresses={response?.additional_addresses}
            email_addresses={response?.email_addresses}
            people={response?.people}
          />
        </AnimatedScrollSection>
      </Box>

      <AnimatedScrollSection
        highlightedSection={highlightedSection}
        setHighlightedSection={setHighlightedSection}
        attribute={BusinessAttributeSection.Industry}
      >
        <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" component="h3" marginBottom={1}>
            Industry
          </Typography>

          <Industry
            industry={response.industry}
            industryProperties={industryProperties}
          />
        </Paper>
      </AnimatedScrollSection>

      <Divider sx={{ marginTop: 5, marginBottom: 5 }} />

      <Grid container spacing={2} columnSpacing={{ md: 3 }}>
        <Grid item xs={12}>
          <AnimatedScrollSection
            highlightedSection={highlightedSection}
            setHighlightedSection={setHighlightedSection}
            attribute={BusinessAttributeSection.LocationData}
          >
            <LocationTile
              primaryAddress={response.primary_address}
              primaryPhoneNumber={response.primary_phone_number}
              addressProperties={addressProperties}
              websiteTraffic={response.website_traffic}
              people={response.people}
              domain={response.domain}
              apiFootTraffic={response.foot_traffic}
              hasVisibleSignage={response.risks.validity.reduce(
                (acc, validity) => {
                  if (validity.name === "has_visible_signage_at_address") {
                    return true;
                  }
                  return acc;
                },
                false
              )}
            />
          </AnimatedScrollSection>
        </Grid>
      </Grid>

      {response.people && (
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.KeyPeople}
        >
          <KeyPeople
            people={response.people.filter(
              (person) => person.source !== DataSource.SUBMITTED
            )}
          />
        </AnimatedScrollSection>
      )}

      <Masonry columns={{ xs: 1, md: isPrint ? 1 : 2 }} spacing={2}>
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.ContactInfo}
        >
          <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "normal", mb: 1 }}
            >
              Contact Info
            </Typography>

            <ContactInfo
              emailAddresses={response.email_addresses}
              primaryPhoneNumber={response?.primary_phone_number}
              additionalPhoneNumbers={response?.additional_phone_numbers}
              domain={domain}
            />
          </Paper>
        </AnimatedScrollSection>

        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.SocialMedia}
        >
          <SocialMedia
            socialMediaLinks={response.social_media_profiles}
            socialMediaProfiles={response.social_media_detailed_profiles}
          />
        </AnimatedScrollSection>

        <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
          <AnimatedScrollSection
            highlightedSection={highlightedSection}
            setHighlightedSection={setHighlightedSection}
            attribute={BusinessAttributeSection.Reviews}
          >
            <CustomerReviews reviews={response?.customer_reviews} />
          </AnimatedScrollSection>

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AnimatedScrollSection
            highlightedSection={highlightedSection}
            setHighlightedSection={setHighlightedSection}
            attribute={BusinessAttributeSection.NewsArticles}
          >
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "normal", mb: 1 }}
            >
              Adverse Media
            </Typography>

            {response.news_articles && response.news_articles.length > 0 ? (
              <NewsArticles articles={response.news_articles} />
            ) : (
              <Typography marginTop={1}>No adverse media found</Typography>
            )}
          </AnimatedScrollSection>
        </Paper>
      </Masonry>

      <AnimatedScrollSection
        highlightedSection={highlightedSection}
        setHighlightedSection={setHighlightedSection}
        attribute={BusinessAttributeSection.ConnectedEntities}
      >
        <Paper elevation={2} sx={{ mt: 3, p: 3, pb: 1 }}>
          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: "normal", mb: 1 }}
          >
            Connected Entities
          </Typography>

          {response.connected_entities.length > 0 ? (
            <ConnectedEntities
              connectedEntities={response.connected_entities}
              rootEntityNameOrDomain={
                response.domain.name || response.name || "Company"
              }
            />
          ) : (
            <Typography marginTop={1}>No connected entities found</Typography>
          )}
        </Paper>
      </AnimatedScrollSection>

      <Divider sx={{ marginTop: 5, marginBottom: 5 }} />

      <AnimatedScrollSection
        highlightedSection={highlightedSection}
        setHighlightedSection={setHighlightedSection}
        attribute={BusinessAttributeSection.WebsiteTraffic}
      >
        <Paper elevation={2} sx={{ p: 3, mb: 3 }} style={{ paddingBottom: 0 }}>
          <Typography variant="h6" component="h3" marginBottom={1}>
            Website Traffic
          </Typography>
          <WebsiteTraffic
            websiteTraffic={response.website_traffic}
            websiteTrafficProperties={websiteTrafficProperties}
          />
        </Paper>
      </AnimatedScrollSection>

      {referralScreenshots && referralScreenshots.length > 0 && (
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.ReferringSites}
        >
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" component="h3">
              High Traffic Referring Sites
              <Tooltip
                title="Referring sites responsible for at least 25% of total traffic"
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            </Typography>

            <Grid
              container
              spacing={2}
              marginTop={0.5}
              columnSpacing={{ md: 3 }}
            >
              {referralScreenshots.map((topRef) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={`${topRef.screenshot_image_link}}`}
                >
                  <Attribute label="URL">
                    <Link
                      href={topRef.url}
                      sx={{ ml: 0.25 }}
                      target="_blank"
                      rel="noopener"
                    >
                      {topRef.url}
                    </Link>
                  </Attribute>
                  <Attribute label="Traffic Share">
                    {Math.round(topRef.traffic_share * 100)}%
                  </Attribute>
                  {topRef.screenshot_image_link ? (
                    <Box
                      overflow="auto"
                      maxHeight={550}
                      borderRadius={1}
                      boxShadow={2}
                    >
                      <ProtectedImageEmbed
                        src={topRef.screenshot_image_link}
                        relayQueryParameters={relayQueryParameters}
                        relayRequestId={relayRequestId}
                      />
                    </Box>
                  ) : (
                    <Typography color={grey[600]} variant="subtitle1">
                      Screenshot unavailable
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Paper>
        </AnimatedScrollSection>
      )}

      {response.website_content && (
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.WebsiteContent}
        >
          <Paper elevation={2} sx={{ p: 3, mt: 3, mb: 3 }}>
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "normal", mb: 1 }}
            >
              Website Content
            </Typography>

            <WebsiteContent
              websiteContent={response.website_content}
              websiteContentProperties={websiteContentProperties}
            />
          </Paper>
        </AnimatedScrollSection>
      )}

      {response.website_content && (
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.WebsitePolicyAnalysis}
        >
          <Paper elevation={2} sx={{ p: 3, mt: 3, mb: 3 }}>
            <Typography
              variant="h6"
              component="h3"
              sx={{ fontWeight: "normal", mb: 1 }}
            >
              Website Policy Analysis
            </Typography>

            <WebsitePolicyAnalysis websiteContent={response.website_content} />
          </Paper>
        </AnimatedScrollSection>
      )}

      {response.website_content?.duplicate_websites &&
        response.website_content?.duplicate_websites.length > 0 && (
          <AnimatedScrollSection
            highlightedSection={highlightedSection}
            setHighlightedSection={setHighlightedSection}
            attribute={BusinessAttributeSection.DuplicateWebsites}
          >
            <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" component="h3">
                Duplicate Websites
                <Tooltip
                  title={
                    websiteContentProperties &&
                    websiteContentProperties.duplicate_websites.description
                  }
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              </Typography>

              <Grid
                container
                spacing={2}
                marginTop={0.5}
                columnSpacing={{ md: 3 }}
              >
                {response.website_content.duplicate_websites.map((match) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    key={`${match.screenshot_image_link}}`}
                  >
                    <Attribute label="URL">
                      <Link
                        href={match.url}
                        sx={{ ml: 0.25 }}
                        target="_blank"
                        rel="noopener"
                      >
                        {match.url}
                      </Link>
                    </Attribute>
                    <Attribute label="Percent Matched">
                      {match.percent_matched}%
                    </Attribute>
                    {match.screenshot_image_link ? (
                      <Box
                        overflow="auto"
                        maxHeight={550}
                        borderRadius={1}
                        boxShadow={2}
                      >
                        <ProtectedImageEmbed
                          src={match.screenshot_image_link}
                          relayQueryParameters={relayQueryParameters}
                          relayRequestId={relayRequestId}
                        />
                      </Box>
                    ) : (
                      <Typography color={grey[600]} variant="subtitle1">
                        Screenshot unavailable
                      </Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </AnimatedScrollSection>
        )}

      {response.domain && (
        <Grid container spacing={2} columnSpacing={{ md: 3 }}>
          <Grid item xs={12} md={12}>
            <AnimatedScrollSection
              highlightedSection={highlightedSection}
              setHighlightedSection={setHighlightedSection}
              attribute={BusinessAttributeSection.DomainAnalysis}
            >
              <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "normal", mb: 1 }}
                >
                  Domain Analysis
                </Typography>

                <DomainAnalysis
                  websiteContent={response.website_content}
                  websiteContentProperties={websiteContentProperties}
                  domain={response.domain}
                  domainProperties={domainProperties}
                />
              </Paper>
            </AnimatedScrollSection>
          </Grid>

          <Grid item xs={12} md={12}>
            <AnimatedScrollSection
              highlightedSection={highlightedSection}
              setHighlightedSection={setHighlightedSection}
              attribute={BusinessAttributeSection.WebserverAnalysis}
            >
              <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "normal", mb: 1 }}
                >
                  Webserver Analysis
                </Typography>

                <WebserverAnalysis
                  domain={response.domain}
                  domainProperties={domainProperties}
                />
              </Paper>
            </AnimatedScrollSection>
          </Grid>
        </Grid>
      )}

      <WebOnly>
        {/* TODO: figure out how to make loading/network traffic/js error detector */}
        {/* in the PDF generator gracefully handle iframed content. */}
        {response.domain?.historical_archives &&
          response.domain.historical_archives.length > 0 && (
            <AnimatedScrollSection
              highlightedSection={highlightedSection}
              setHighlightedSection={setHighlightedSection}
              attribute={BusinessAttributeSection.HistoricalScreenshots}
            >
              <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" component="h3">
                  Historical Website Views
                </Typography>

                <Grid
                  container
                  spacing={2}
                  marginTop={0.5}
                  columnSpacing={{ md: 3 }}
                >
                  {response.domain.historical_archives.map(
                    (historicalArchive) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        key={`${historicalArchive.url}-${historicalArchive.taken_date}`}
                      >
                        <Typography variant="subtitle1" textAlign="center">
                          {dayjs(historicalArchive.taken_date).format(
                            "MMMM, YYYY"
                          )}
                        </Typography>
                        <Box overflow="auto" maxHeight={550} borderRadius={1}>
                          {historicalArchive.url.includes("ae.truebiz.io") ? (
                            <ProtectedImageEmbed
                              src={historicalArchive.url}
                              relayQueryParameters={relayQueryParameters}
                              relayRequestId={relayRequestId}
                            />
                          ) : (
                            <ProtectedIframeEmbed url={historicalArchive.url} />
                          )}
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </Paper>
            </AnimatedScrollSection>
          )}
      </WebOnly>

      {response.ecommerce && (
        <AnimatedScrollSection
          highlightedSection={highlightedSection}
          setHighlightedSection={setHighlightedSection}
          attribute={BusinessAttributeSection.Ecommerce}
        >
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" component="h3" marginBottom={1}>
              Ecommerce
            </Typography>
            <Ecommerce
              ecommerce={response.ecommerce}
              ecommerceProperties={ecommerceProperties}
            />
          </Paper>
        </AnimatedScrollSection>
      )}

      <AddDomainModal
        visible={(domain && showAddBlockedDomainForm) || false}
        onClose={() => setShowAddBlockedDomainForm(false)}
        onCreateBlockedDomain={handleAddBlockedDomain}
        specifiedDomain={domain}
      />
    </Box>
  );
}

export default function BusinessAttributes() {
  const api = useTrueBizApi();
  const [schema, setSchema] = useState<OpenapiSchemaResponse | null>(null);

  useEffect(() => {
    const loadSchema = async () => {
      try {
        const schema = await api.schemaLookup();
        setSchema(schema);
      } catch {
        // pass
      }
    };

    loadSchema();
  }, [api]);

  return !schema ? (
    <CircularProgress />
  ) : (
    <BusinessAttributesWithoutDataLoader schema={schema} />
  );
}
