import { useMemo } from "react";

import ReactGaugeComponent from "react-gauge-component";

import { Box, Stack, useTheme } from "@mui/material";
import type { StackProps } from "@mui/material/Stack";
import { lab } from "d3-color";
import type { LabColor } from "d3-color";

import styles from "./styles.module.css";

export type GaugeSeverity = "good" | "fair" | "poor";

export interface Props extends Omit<StackProps, "children"> {
  severity: GaugeSeverity;
}

export default function Gauge({ severity, ...etc }: Props) {
  const theme = useTheme();

  const colors = useMemo(() => {
    const emphasize = (c: LabColor) => {
      let emphasized = c.brighter(0.27);
      emphasized.opacity = 1;
      return emphasized;
    };

    const deEmphasize = (c: LabColor) => {
      let deEmphasized = c.brighter(0.85);
      deEmphasized.opacity = 0.28;
      return deEmphasized;
    };

    const error = lab(theme.palette.error.light);
    const warning = lab(theme.palette.warning.light);
    const success = lab(theme.palette.success.light);

    if (!error || !warning || !success) {
      return undefined;
    }

    if (severity === "poor") {
      return [
        emphasize(error).formatRgb(),
        deEmphasize(warning).formatRgb(),
        deEmphasize(success).formatRgb(),
      ];
    }

    if (severity === "fair") {
      return [
        deEmphasize(error).formatRgb(),
        emphasize(warning).formatRgb(),
        deEmphasize(success).formatRgb(),
      ];
    }

    if (severity === "good") {
      return [
        deEmphasize(error).formatRgb(),
        deEmphasize(warning).formatRgb(),
        emphasize(success).formatRgb(),
      ];
    }

    return [error.formatRgb(), warning.formatRgb(), success.formatRgb()];
  }, [theme, severity]);

  return (
    <Stack alignItems="center" mt={0} {...etc}>
      {/* our shitty gauge library does not allow for resizing gages. period. */}
      {/* so we have to scale the svg down in css then center a fixed width container */}
      {/* inside of a responsively sized container. this kind of works */}
      <Box
        maxWidth="70%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={-5}
      >
        <Box width="300px">
          <ReactGaugeComponent
            type="semicircle"
            value={severity === "poor" ? 16.5 : severity === "fair" ? 50 : 82.5}
            pointer={{ type: "arrow", animate: false }}
            labels={{
              valueLabel: {
                formatTextValue: () => severity,
                matchColorWithArc: true,
                style: {
                  textShadow: "none",
                  textTransform: "uppercase",
                  fontSize: "28px",
                  fontWeight: "bold",
                },
              },
            }}
            arc={{
              nbSubArcs: 3,
              colorArray: colors,
              cornerRadius: 2,
              width: 0.3,
            }}
            className={styles.gauge}
          />
        </Box>
      </Box>
    </Stack>
  );
}
