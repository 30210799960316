import { Grid } from "@mui/material";

import Table from "./Table";
import Graph from "./Graph";

import APIConnectedEntity from "../../../types/APIConnectedEntity";
import WebOnly from "../../WebOnly";

export interface Props {
  connectedEntities: APIConnectedEntity[];
  rootEntityNameOrDomain: string;
}

export default function ConnectedEntities({
  connectedEntities,
  rootEntityNameOrDomain,
}: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <WebOnly>
          <Graph
            connectedEntities={connectedEntities}
            rootEntityNameOrDomain={rootEntityNameOrDomain}
          />
        </WebOnly>
      </Grid>
      <Grid item xs={12} md={6}>
        <Table connectedEntities={connectedEntities} />
      </Grid>
    </Grid>
  );
}
