import { ascending } from "d3-array";
import { uniq, zip } from "lodash";

import APIConnectedEntity, {
  APIConnectionSource,
} from "../../../types/APIConnectedEntity";

export function isAlert(connectedEntity: APIConnectedEntity) {
  if (connectedEntity.content_flags && connectedEntity.content_flags.length) {
    return true;
  }
  if (connectedEntity.multi_level_marketing_name) {
    return true;
  }
  return false;
}

export const sortConnectedEntities = (
  connectedEntities: APIConnectedEntity[]
) => {
  const weightedConnectionSourceTypes = uniq([
    "Duplicate website text",
    "Shared executive",
    "Shared phone number",
    "Shared credentials",
    "External email domain",
    "Cohosted domain",
    "Searched domain redirects",
    "Redirect domain",
    "Referring site",
    "Phone owner",
    "Associated person",
  ]);

  const byWeightedSourceType = (
    sourceA: APIConnectionSource,
    sourceB: APIConnectionSource
  ) => {
    const aWeight = weightedConnectionSourceTypes.indexOf(sourceA.type);
    const bWeight = weightedConnectionSourceTypes.indexOf(sourceB.type);

    if (aWeight === -1 && bWeight === -1) {
      // neither were weighted, alphabetize
      return ascending(sourceA.type, sourceB.type);
    }

    if (aWeight !== -1 && bWeight === -1) {
      return -1; // a has a weight so it comes first
    }

    if (bWeight !== -1 && aWeight === -1) {
      return 1; // b has a weight so it comes first
    }

    return ascending(aWeight, bWeight); // sort by weights
  };

  const byWeightedSourceTypeThenNameOrDomain = (
    entityA: APIConnectedEntity,
    entityB: APIConnectedEntity
  ): number => {
    // 1. sort by source types
    //
    // we're basically sorting n times here, one time for the length of the shorter of the two lists of sources.
    // we want to end up with an ordering like
    //
    // [a]
    // [a, b]
    // [a, c]
    // [a, c]
    // [b, c, d]
    // []
    //
    const sortedSources = zip(
      // this should really be called zipLongest
      entityA.sources.slice().sort(byWeightedSourceType),
      entityB.sources.slice().sort(byWeightedSourceType)
    );

    for (const [aSource, bSource] of sortedSources) {
      if (aSource === undefined && bSource === undefined) {
        // this shouldn't happen, but typescript doesn't know that
        break;
      }

      if (aSource === undefined) {
        // if we've gotten here all previous elements in both lists were equal
        // but the left list was shorter, and comes first
        return -1;
      }

      if (bSource === undefined) {
        // if we've gotten here all previous elements in both lists were equal
        // but the right list was shorter and comes first
        return 1;
      }

      if (aSource.type === bSource.type) {
        // we can't make a determination yet, keep looking
        continue;
      }

      return byWeightedSourceType(aSource, bSource);
    }

    // 2. all sources were equal, or neither list had any sources,
    // so sort by other criteria. first check to see if the entity is flagged

    const aFlagged = isAlert(entityA);
    const bFlagged = isAlert(entityB);

    if (aFlagged && !bFlagged) {
      // a is flagged and so should come first
      return -1;
    }

    if (!aFlagged && bFlagged) {
      // b is flagged and so should come first
      return 1;
    }

    // 3. if both are flagged, or neither or flagged
    // so alphabetize on the name if we have it or the domain if not
    const aLabel = entityA.name || entityA.domain || undefined;
    const bLabel = entityB.name || entityB.domain || undefined;

    return ascending(aLabel, bLabel);
  };

  return connectedEntities.slice().sort(byWeightedSourceTypeThenNameOrDomain);
};

export function getTooltipLabel(sourceType: string) {
  switch (sourceType) {
    case "External email domain":
      return "Company uses an email address hosted by this website.";
    case "Referring site":
      return "This website refers more than 25% of the company's traffic.";
    case "Duplicate website text":
      return "This website shares a meaningful amount of text context with company's website";
    case "Shared executive":
      return "A company executive is also an active executive of this entity.";
    case "Shared phone number":
      return "Company's primary phone number is used by another entity.";
    case "Shared credentials":
      return "Private credentials for a website service are used by this entity.";
    case "Cohosted domain":
      return "This domain is hosted at the same private IP address at the company's website.";
    case "Redirect domain":
      return "This domain redirects to the company's website.";
    case "Searched domain redirects":
      return "The searched domain redirects to a different domain.";
    case "Associated person":
      return "Records indicate this person is linked to the company or is an associate of someone directly linked to the company";
    case "Phone owner":
      return "Records indicate that this person owns a phone number found to be associated with the company";
    default:
      return null;
  }
}
