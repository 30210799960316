import { format, setHours } from "date-fns";
import ReactApexChart from "react-apexcharts";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { Box, Paper, useTheme } from "@mui/material";
import {
  getDisplayFootTrafficValue,
  getFootTrafficData,
  getFootTrafficScore,
} from "./utils";
import APIFootTraffic from "../../../../types/APIFootTraffic";

export default function FootTrafficGraph({
  apiFootTraffic,
  componentHeightPx,
}: {
  apiFootTraffic: APIFootTraffic | null;
  componentHeightPx: number;
}) {
  const theme = useTheme();

  const footTraffic = getFootTrafficData(apiFootTraffic);
  const footTrafficScore = getFootTrafficScore(footTraffic);

  if (!footTrafficScore) {
    return (
      <Paper elevation={2}>
        <Box
          height={componentHeightPx}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            position="relative"
            zIndex={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <DirectionsWalkIcon fontSize="large" />
            <strong>No foot traffic data available</strong>
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={2}>
      <Box height={componentHeightPx}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={componentHeightPx}
        >
          <Box minWidth="100%">
            <ReactApexChart
              options={{
                chart: {
                  height: componentHeightPx * 0.9,
                  width: "100%",
                  type: "heatmap",
                  toolbar: {
                    show: false,
                  },
                },
                colors: [theme.palette.primary.main],
                dataLabels: {
                  enabled: false,
                },
                title: {
                  text: "Foot traffic",
                  align: "center",
                },
                tooltip: {
                  custom: function ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                  }) {
                    const day = w.globals.seriesNames[seriesIndex];
                    const hour = w.globals.labels[dataPointIndex];
                    const trafficValue = getDisplayFootTrafficValue(
                      series[seriesIndex][dataPointIndex]
                    );
                    return `<div class="apexcharts-active">
                              <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; padding-bottom: 0px !important;">
                                  <div class="apexcharts-tooltip-text">
                                      <div class="apexcharts-tooltip-y-group">
                                          <span class="apexcharts-tooltip-text-label">${day} ${hour}: <b>${trafficValue}</b></span><br>
                                      </div>
                                  </div>
                              </div>
                            </div>`;
                  },
                  y: {
                    formatter(val: number, opts?: any): string {
                      return getDisplayFootTrafficValue(val);
                    },
                  },
                },
                xaxis: {
                  tooltip: {
                    enabled: false,
                  },
                },
              }}
              series={footTraffic.reverse().map((day) => {
                const newData = day.data.map(({ x, y }) => {
                  // Convert hour number to "h a" (e.g., "1 AM", "12 PM")
                  const hourLabel = format(setHours(new Date(), x), "h a");
                  return {
                    x: hourLabel,
                    y,
                  };
                });

                return {
                  name: day.name,
                  data: newData,
                };
              })}
              type="heatmap"
              height={350}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
