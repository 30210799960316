import { Typography, Link } from "@mui/material";

export interface Props {
  name: string | null;
  domain: string | null;
}

export default function NameLink({ name, domain }: Props) {
  if (name && domain) {
    return (
      <Link href={`https://${domain}`} target="_blank" rel="noopener">
        <Typography variant="subtitle1">{name}</Typography>
      </Link>
    );
  }

  if (domain && name === null) {
    return (
      <Link href={`https://${domain}`} target="_blank" rel="noopener">
        <Typography variant="subtitle1">{domain}</Typography>
      </Link>
    );
  }

  if (name && domain === null) {
    return <Typography variant="subtitle1">{name}</Typography>;
  }

  return null;
}
