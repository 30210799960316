import {
  Typography,
  Modal,
  Paper,
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import APICompanyMatchProfile from "../../../types/APICompanyMatchProfile";

export default function ContentModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
  contentCategories,
  readOnly,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: APICompanyMatchProfile;
  updateMatchProfile: any;
  contentCategories: string[];
  readOnly: boolean;
}) {
  const updateContentCategory = (category: string, newValue: string) => {
    if (newValue === "allowed") {
      updateMatchProfile({
        red_content_categories: [...matchProfile.red_content_categories].filter(
          (rowCat) => rowCat !== category
        ),
        yellow_content_categories: [
          ...matchProfile.yellow_content_categories,
        ].filter((rowCat) => rowCat !== category),
      });
    } else if (newValue === "yellow") {
      const newYellowUndeduped = [
        category,
        ...matchProfile.yellow_content_categories,
      ];

      updateMatchProfile({
        red_content_categories: [...matchProfile.red_content_categories].filter(
          (rowCat) => rowCat !== category
        ),
        yellow_content_categories: [...new Set(newYellowUndeduped)],
      });
    } else {
      const newRedUndeduped = [
        category,
        ...matchProfile.red_content_categories,
      ];

      updateMatchProfile({
        red_content_categories: [...new Set(newRedUndeduped)],
        yellow_content_categories: [
          ...matchProfile.yellow_content_categories,
        ].filter((rowCat) => rowCat !== category),
      });
    }
  };

  const updateAllAllowed = () => {
    updateMatchProfile({
      red_content_categories: [],
      yellow_content_categories: [],
    });
  };

  const updateAllYellow = () => {
    updateMatchProfile({
      red_content_categories: [],
      yellow_content_categories: contentCategories.map((category) => category),
    });
  };

  const updateAllRed = () => {
    updateMatchProfile({
      red_content_categories: contentCategories.map((category) => category),
      yellow_content_categories: [],
    });
  };

  const getToggleGroupValue = (category: string) => {
    if (matchProfile.red_content_categories.includes(category)) {
      return "red";
    }

    if (matchProfile.yellow_content_categories.includes(category)) {
      return "yellow";
    }

    return "allowed";
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Content Categories
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 3, color: grey[800], fontStyle: "italic" }}
            >
              Set categories to "Prohibited" if you want a business to fail if
              we find this kind of content associated with the business. Set to
              "High Risk" if you would like to be alerted to this kind of
              content and have this finding contribute to a business failure
              recommendation. Set to "Allowed" to ignore this kind of content.
            </Typography>

            {!readOnly && (
              <Box sx={{ mb: 3, mt: -1 }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={updateAllAllowed}
                >
                  Set All Allowed
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={updateAllYellow}
                >
                  Set All High Risk
                </Button>

                <Button size="small" variant="contained" onClick={updateAllRed}>
                  Set All Prohibited
                </Button>
              </Box>
            )}

            {contentCategories.map((category) => (
              <Box
                key={category}
                sx={{ mb: 1, display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    width: 255,
                    verticalAlign: "middle",
                  }}
                >
                  <ToggleButtonGroup
                    value={getToggleGroupValue(category)}
                    exclusive
                    onChange={(event, newValue) =>
                      updateContentCategory(category, newValue)
                    }
                    size="small"
                    color="primary"
                    sx={{ display: "inline-block", width: 300 }}
                    disabled={readOnly}
                  >
                    <ToggleButton
                      color="success"
                      value="allowed"
                      aria-label="left aligned"
                    >
                      Allowed
                    </ToggleButton>
                    <ToggleButton
                      color="warning"
                      value="yellow"
                      aria-label="centered"
                    >
                      High Risk
                    </ToggleButton>
                    <ToggleButton
                      color="error"
                      value="red"
                      aria-label="right aligned"
                    >
                      Prohibited
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ display: "inline-block", width: 700, pt: 0, mt: 0 }}>
                  <Typography variant="subtitle1">{category}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
