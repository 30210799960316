import { useMemo } from "react";
import { Box, Grid, Typography, Stack, Link, useTheme } from "@mui/material";
import { capitalize } from "lodash";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import TrustRecommendation from "./TrustRecommendation";
import Tile, { TileType, getTileForRisk } from "./Tile";
import { getSectionForRisk } from "./utils";

import APIRecommendation from "../../../types/APIRecommendation";
import APIRiskIndicators from "../../../types/APIRiskIndicators";
import { BusinessAttributeSection } from "../../../types/BusinessAttributeSection";

export interface Props {
  recommendation: APIRecommendation | null;
  setHighlightedSection: (section: BusinessAttributeSection) => void;
  risks: APIRiskIndicators;
  trackingId: UUID | null | undefined;
}

export default function FindingsV2({
  recommendation,
  setHighlightedSection,
  risks,
  trackingId,
}: Props) {
  // match the heights to the ss image on the right
  const TILE_ROW_HEIGHT = "465px";

  const theme = useTheme();

  const otherRisks = useMemo(() => {
    const supportedTiles = [
      TileType.SENTIMENT,
      TileType.CONTENT,
      TileType.WEBSITE_OPERATIONS,
      TileType.PHYSICAL_OPERATIONS,
    ];

    return {
      risk: risks.risk.filter(
        (risk) => !supportedTiles.includes(getTileForRisk(risk.name))
      ),
      notice: risks.notice.filter(
        (notice) => !supportedTiles.includes(getTileForRisk(notice.name))
      ),
      validity: risks.validity.filter(
        (validity) => !supportedTiles.includes(getTileForRisk(validity.name))
      ),
    };
  }, [risks]);

  const hasOtherRisks =
    otherRisks.risk.length > 0 ||
    otherRisks.notice.length > 0 ||
    otherRisks.validity.length > 0;

  const PASS = (recommendation?.decision || "").toLowerCase() === "pass";

  return (
    <>
      <TrustRecommendation
        pass={recommendation ? PASS : null}
        trackingId={trackingId}
        risks={risks}
      />
      <Grid container spacing={2} mb={4} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={6} lg={3}>
          <Tile
            type={TileType.SENTIMENT}
            title="Sentiment"
            risks={risks}
            setHighlightedSection={setHighlightedSection}
            height={TILE_ROW_HEIGHT}
            pass={PASS}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Tile
            type={TileType.CONTENT}
            title="Content"
            risks={risks}
            setHighlightedSection={setHighlightedSection}
            height={TILE_ROW_HEIGHT}
            pass={PASS}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Tile
            type={TileType.WEBSITE_OPERATIONS}
            title="Website Operations"
            risks={risks}
            setHighlightedSection={setHighlightedSection}
            height={TILE_ROW_HEIGHT}
            pass={PASS}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Tile
            type={TileType.PHYSICAL_OPERATIONS}
            title="Physical Operations"
            risks={risks}
            setHighlightedSection={setHighlightedSection}
            height={TILE_ROW_HEIGHT}
            pass={PASS}
          />
        </Grid>
      </Grid>
      {hasOtherRisks && (
        <Box>
          <Typography variant="subtitle1" textTransform="uppercase">
            Other Findings
          </Typography>
          <Stack direction="row" gap="0.75em" flexWrap="wrap">
            {otherRisks.risk.map((risk) => (
              <Link
                key={risk.name}
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.125em"
                flexShrink={0}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHighlightedSection(getSectionForRisk(risk.name));
                }}
                color={theme.palette.grey[700]}
              >
                <CancelOutlinedIcon fontSize="small" />{" "}
                {capitalize(risk.display_name)}
              </Link>
            ))}
            {otherRisks.notice.map((notice) => (
              <Link
                key={notice.name}
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.125em"
                flexShrink={0}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHighlightedSection(getSectionForRisk(notice.name));
                }}
                color={theme.palette.grey[700]}
              >
                <InfoOutlinedIcon fontSize="small" />{" "}
                {capitalize(notice.display_name)}
              </Link>
            ))}
            {otherRisks.validity.map((validity) => (
              <Link
                key={validity.name}
                component={Box}
                alignItems="center"
                display="flex"
                gap="0.125em"
                flexShrink={0}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHighlightedSection(getSectionForRisk(validity.name));
                }}
                color={theme.palette.grey[700]}
              >
                <CheckCircleOutlinedIcon fontSize="small" />{" "}
                {capitalize(validity.display_name)}
              </Link>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}
