import { useMemo, ReactNode, useId, useState, useLayoutEffect } from "react";
import { orderBy, capitalize } from "lodash";
import { useOverflowDetector } from "react-detectable-overflow";

import { Paper, Stack, Typography, useTheme, Link, Box } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Carousel from "react-material-ui-carousel";

import Gauge, { GaugeSeverity } from "./Gauge";
import { getTileForRisk, TileType } from "./utils";

import { BusinessAttributeSection } from "../../../../types/BusinessAttributeSection";
import APIRiskIndicators from "../../../../types/APIRiskIndicators";
import { getSectionForRisk } from "../utils";

export { TileType, getTileForRisk } from "./utils";

export type Severity = GaugeSeverity | "unknown";

export interface Props {
  type: TileType;
  title: ReactNode;
  setHighlightedSection: (section: BusinessAttributeSection) => void;
  risks: APIRiskIndicators;
  height?: number | string | undefined;
  pass?: boolean;
}

export default function Tile({
  type,
  title,
  risks,
  setHighlightedSection,
  height,
  pass,
}: Props) {
  const id = useId();
  const theme = useTheme();
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const riskRisks = useMemo(
    () =>
      orderBy(
        (risks.risk || []).filter((r) => getTileForRisk(r.name) === type),
        "display_name"
      ),
    [risks, type]
  );
  const noticeRisks = useMemo(
    () =>
      orderBy(
        (risks.notice || []).filter((n) => getTileForRisk(n.name) === type),
        "display_name"
      ),
    [risks, type]
  );
  const validityRisks = useMemo(
    () =>
      orderBy(
        (risks.validity || []).filter((v) => getTileForRisk(v.name) === type),
        "display_name"
      ),
    [risks, type]
  );

  const severity = pass
    ? "good"
    : riskRisks.length > 0
    ? "poor"
    : noticeRisks.length > 0
    ? "fair"
    : validityRisks.length > 0
    ? "good"
    : "unknown";

  const pages = useMemo(() => {
    const computedPages = [];

    const renderedRisks = riskRisks.map((risk) => (
      <Link
        key={risk.name}
        component={Box}
        alignItems="center"
        display="flex"
        gap="0.25em"
        flexShrink={0}
        style={{ cursor: "pointer" }}
        color={theme.palette.error.main}
        onClick={() => {
          setHighlightedSection(getSectionForRisk(risk.name));
        }}
      >
        <CancelOutlinedIcon /> {capitalize(risk.display_name)}
      </Link>
    ));

    const renderedNotices = noticeRisks.map((notice) => (
      <Link
        key={notice.name}
        component={Box}
        alignItems="center"
        display="flex"
        gap="0.25em"
        flexShrink={0}
        style={{ cursor: "pointer" }}
        color={theme.palette.warning.main}
        onClick={() => {
          setHighlightedSection(getSectionForRisk(notice.name));
        }}
      >
        <InfoOutlinedIcon /> {capitalize(notice.display_name)}
      </Link>
    ));

    const renderedValidities = validityRisks.map((validity) => (
      <Link
        key={validity.name}
        component={Box}
        alignItems="center"
        display="flex"
        gap="0.25em"
        flexShrink={0}
        style={{ cursor: "pointer" }}
        color={theme.palette.success.main}
        onClick={() => {
          setHighlightedSection(getSectionForRisk(validity.name));
        }}
      >
        <CheckCircleOutlinedIcon /> {capitalize(validity.display_name)}
      </Link>
    ));

    const renderedPageItems = renderedRisks
      .concat(renderedNotices)
      .concat(renderedValidities);

    let currentPage: typeof renderedPageItems = [];

    for (const renderedItem of renderedPageItems) {
      if (currentPage.length >= itemsPerPage) {
        computedPages.push(currentPage);
        currentPage = [];
      }
      currentPage.push(renderedItem);
    }

    if (currentPage.length > 0) {
      computedPages.push(currentPage);
      currentPage = [];
    }

    return computedPages;
  }, [
    itemsPerPage,
    riskRisks,
    noticeRisks,
    validityRisks,
    setHighlightedSection,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.main,
  ]);

  const { ref: overflowRef, overflow } = useOverflowDetector();

  // this is hacky, but does at least kind of hacks around
  // our carousel library not being able to handle responsive heights
  // overflow in the most ugly corner cases
  useLayoutEffect(() => {
    if (!overflow) return;
    setItemsPerPage((x) => Math.max(x - 1, 1));
  }, [overflow]);

  return (
    <Paper style={{ height, padding: "1em", overflow: "hidden" }}>
      <Stack height="100%">
        <Stack alignItems="center" pt={2}>
          {severity !== "unknown" ? (
            <Gauge severity={severity} />
          ) : (
            <Box
              minHeight={95}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              &mdash;
            </Box>
          )}
          <Typography
            variant="h6"
            textAlign="center"
            height="30px"
            lineHeight={1.15}
          >
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            textTransform="uppercase"
            textAlign="center"
            color={theme.palette.grey[500]}
            mt={3}
            lineHeight={1.25}
          >
            Factors
          </Typography>
        </Stack>
        <Stack gap="0.5em" mt={1} height={220} ref={overflowRef}>
          <Carousel
            autoPlay={false}
            cycleNavigation={false}
            height={200}
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "visible",
            }}
            indicators={pages.length > 1}
            indicatorContainerProps={{
              style: {
                position: "absolute",
                bottom: "-1em",
              },
            }}
          >
            {pages.map((page, i) => (
              <Stack key={`carousel_page__${id}__${i}__${page[0].key}`}>
                <Stack
                  gap="0.5em"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {page}
                </Stack>
              </Stack>
            ))}
          </Carousel>
        </Stack>
      </Stack>
    </Paper>
  );
}
