export enum FootTrafficValue {
  Closed = "CLOSED",
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
  Highest = "HIGHEST",
}

export interface APIFootTrafficHour {
  ordinal: number;
  occupancy: FootTrafficValue;
}

export interface APIFootTrafficDay {
  name: string;
  hours: APIFootTrafficHour[];
}

export default interface APIFootTraffic {
  projections: APIFootTrafficDay[];
  traffic_score: string;
  hours_open: number[][];
}
